import React from 'react'
import { StyleSheet } from 'react-native';

import StyledActivityIndicator from './StyledActivityIndicator';

/**
 * Use to indicate that the screen is loading.
 */

export default function Loading() {
    return (
        <StyledActivityIndicator style={styles.loadingIndicator} />
    );
}

const styles = StyleSheet.create({
    loadingIndicator: {
        marginVertical: 24,
    },
});
