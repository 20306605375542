import React, { useCallback } from 'react';
import { Image, View } from 'react-native';
import { useFonts, OpenSans_400Regular, OpenSans_600SemiBold, OpenSans_700Bold } from '@expo-google-fonts/open-sans';
import * as SplashScreen from 'expo-splash-screen';

import { useAuth } from './AuthProvider';

SplashScreen.preventAutoHideAsync();

// see https://docs.expo.dev/versions/latest/sdk/splash-screen/?redirected 
export default function WaitForAppToLoad({ children }) {
    const [fontsLoaded] = useFonts({
        OpenSans_400Regular,
        OpenSans_600SemiBold,
        OpenSans_700Bold,
    });
    const { loading: authLoading } = useAuth();

    // wait for layout before hiding splash screen
    const onLayout = useCallback(async () => {
        if (fontsLoaded && !authLoading) {
            await SplashScreen.hideAsync();
        }
    }, [fontsLoaded, authLoading]);

    if (!fontsLoaded || authLoading) {
        return null;
    }

    return (
        <>
            {children}
            <View onLayout={onLayout}></View>
        </>
    )
}
