import React from 'react';
import { StyleSheet, View } from 'react-native';

import StyledScrollView from '../components/StyledScrollView';
import Spacer from '../components/Spacer';
import Container from '../components/Container';
import LinkListItem from '../components/LinkListItem';
import LinksIcon from '../icons/LinksIcon';
import Theme from '../App/Theme';

const LINKS = [
    [
        { title: 'Out of Work Lists', url: 'https://ua254.ca/' },
        { title: 'Payment Portal', url: 'https://ua254.ca/' },
        { title: 'Resources', url: 'https://ua254.ca/' },
    ],
]

export default function ExternalLinksScreen() {
    return (
        <StyledScrollView style={styles.main} contentContainerStyle={styles.mainContent}>
            <Container style={styles.iconWrapper}>
                <LinksIcon color={Theme.colors.largeIcon} />
            </Container>
            {LINKS.map((linkGroup, groupIndex) => (
                <View key={`link_group_${groupIndex}`}>
                    {groupIndex !== 0 && (
                        <View key={`separator_${groupIndex}`} style={styles.sectionSeparator}></View>
                    )}
                    {linkGroup.map((linkItem, itemIndex) => (
                        <LinkListItem color="secondary" key={itemIndex} title={linkItem.title} href={linkItem.url} />
                    ))}
                </View>
            ))}
            <Spacer />
        </StyledScrollView>
    )
}

const styles = StyleSheet.create({
    main: {
        flex: 1,
    },
    iconWrapper: {
        alignItems: 'center',
        justifyContent: 'center',
        paddingVertical: 32,
    },
    mainContent: {
        flex: 1,
    },
    sectionSeparator: {
        'height': 28,
    },
});
