import React from 'react'
import { View, Text, StyleSheet } from 'react-native'
import Center from '../components/Center'
import Container from '../components/Container'
import StyledLink from '../components/StyledLink'

import StyledText from '../components/StyledText'

export default function NotFoundScreen() {
    return (
        <Container style={styles.container}>
            <StyledText style={styles.message}>We're sorry, but it seems the page you've been directed to could not be found.</StyledText>
            <StyledText style={styles.message}>Feel free to contact <StyledLink to="/contact/ua179">UA 179</StyledLink> or <StyledLink to="/contact/sask-piping">Sask Piping</StyledLink> through our contact pages or search for an existing user from the <StyledLink to="/search">Search</StyledLink> page.</StyledText>
        </Container>
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        // justifyContent: 'center',
        paddingVertical: 24,
    },
    message: {
        textAlign: 'center',
        marginBottom: 8,
    }
})
