import { Platform } from 'react-native';
import Constants from 'expo-constants';
import * as Updates from 'expo-updates';
import Config from '../App/Config';

import * as Sentry from 'sentry-expo';
import { useAuth } from '../App/AuthProvider';

// sentry
Sentry.init({
    dsn: 'https://3890c50f2899432faa10ecea21276788@o125206.ingest.sentry.io/6147979',
    environment: Updates.channel,
    enableInExpoDevelopment: false,
    //enableNative: Platform.OS == 'ios',
    debug: false,
});

let LOGS = [];

export function getLogs() {
    return LOGS;
}

export function isDebug() {
    return Config.debug;
}

export function isDev() {
    const { userId } = useAuth();
    return Config.developerList.includes(userId);
}

export function clear() {
    LOGS = [];
}

//(function () {
//    var oldLog = console.log;
//    console.log = function (...args) {
//        LOGS.push({
//            level: 'info',
//            message: args.reduce((prev, cur) => prev + ' ' + JSON.stringify(cur, null, 2), ''),
//        })
//        if (isDebug())
//            oldLog.apply(console, args);
//    };
//})();

//(function () {
//var oldWarn = console.warn;
//    console.warn = function (...args) {
//        LOGS.push({
//            level: 'warn',
//            message: args.reduce((prev, cur) => prev + ' ' + JSON.stringify(cur, null, 2), ''),
//        })
//        if (isDebug())
//            oldWarn.apply(console, args);
//    };
//})();

(function () {
    var oldError = console.error;
    console.error = function (...args) {
        LOGS.push({
            level: 'error',
            message: args.reduce((prev, cur) => prev + ' ' + JSON.stringify(cur, null, 2), ''),
        })

        if (isDebug())
            oldError.apply(console, args);
    };
})();
