import React, { createContext, useEffect, useState } from 'react';
import { Platform } from 'react-native';
import { gql, useMutation, useApolloClient, ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client';

import * as Store from '../util/store';
import Config from './Config';

// store keys
const TOKEN_KEY_STORE = 'userToken';
const REFRESH_TOKEN_KEY_STORE = 'userRefreshToken';

// mutations
const SIGN_IN = gql`
    mutation TokenAuth($username: String!, $password: String!) {
        tokenAuth(username: $username, password: $password) {
            token
            payload
            refreshToken
        }
    }
`;

const VERIFY_TOKEN = gql`
    mutation VerifiyToken($token: String!) {
        verifyToken(token: $token) {
            payload
        }
    }
`;

const REFRESH_TOKEN = gql`
    mutation RefreshToken($refreshToken: String) {
        refreshToken(refreshToken: $refreshToken) {
            payload
            refreshExpiresIn
            token
            refreshToken
        }
    }
`;

const REVOKE_TOKEN = gql`
    mutation RevokeToken($refreshToken: String) {
        revokeToken(refreshToken:$refreshToken) {
            revoked
        }
    }
`;

// apollo client for verifying/refreshing token
const authApiClient = new ApolloClient({
    link: createHttpLink({ uri: Config.graphqlUri }),
    cache: new InMemoryCache(),
    credentials: 'include',
    fetchOptions: {
        mode: 'no-cors',
    },
})

// the token
let TOKEN = null;
let PAYLOAD = null;

const listeners = new Set();

export const getToken = () => {
    return TOKEN;
}

export const getPayload = () => {
    return PAYLOAD;
}

const getRefreshToken = async () => {
    return await Store.getItemAsync(REFRESH_TOKEN_KEY_STORE);
}

const setToken = async (token, payload) => {
    TOKEN = token;
    PAYLOAD = payload;

    listeners.forEach(listener => listener());

    try {
        await Store.setItemAsync(TOKEN_KEY_STORE, token);
    } catch (e) {
        console.log('Error saving token to storage: ', e);
    }
};

const useToken = () => {
    const [localToken, setLocalToken] = useState(TOKEN);
    const [localPayload, setLocalPayload] = useState(TOKEN);
    useEffect(() => {
        const listener = () => {
            setLocalToken(TOKEN);
            setLocalPayload(PAYLOAD);
        };
        listeners.add(listener);
        listener(); // in case it's already changed
        return () => listeners.delete(listener); // cleanup
    }, []);
    return [localToken, localPayload, setToken];
};

export const deleteToken = async () => {
    TOKEN = null;
    PAYLOAD = null;

    listeners.forEach(listener => listener());

    try {
        await Store.deleteItemAsync(TOKEN_KEY_STORE);
        await Store.deleteItemAsync(REFRESH_TOKEN_KEY_STORE);
    } catch (e) {
        console.log('Error deleting token from storage: ', e);
    }
}

const setRefreshToken = async (refreshToken) => {
    try {
        await Store.setItemAsync(REFRESH_TOKEN_KEY_STORE, refreshToken);
    } catch (e) {
        console.log('Error saving refresh token to storage: ', e);
    }
}

// sign in hook
export const useSignIn = () => {
    const [mutate, mutationResults] = useMutation(SIGN_IN, {
        onCompleted: (data) => {
            //console.log('sign in completed: ', data)
            const { token, refreshToken, payload } = data.tokenAuth;
            setToken(token, payload);
            setRefreshToken(refreshToken);
            console.log(`user ${payload.username} logging in with token ${token.slice(0, 4)}...${token.slice(-4)}`);
        },
    });

    const signIn = async (username, password) => {
        try {
            await mutate({
                variables: {
                    username,
                    password,
                }
            });
        } catch (e) {
            console.log('Error signing in: ', e?.message);
        }
    }

    return [signIn, mutationResults];
}

// sign out hook
export const useSignOut = () => {
    const [revokeToken,] = useMutation(REVOKE_TOKEN, {
        onCompleted: () => console.log('token revoked'),
        onError: (e) => console.log('Error deleting token from server: ', e?.message),
    });

    const signOut = async () => {
        console.log('logging out');
        deleteToken();
        revokeToken({
            variables: {
                refreshToken: await getRefreshToken(),
            },
        });
    }

    return () => signOut();
}

// refresh token asynchronously
export const refreshTokenAsync = async () => {
    let refreshToken;
    console.log('refreshing token...');

    // fetch the refresh token from storage
    try {
        refreshToken = await Store.getItemAsync(REFRESH_TOKEN_KEY_STORE);
    } catch (e) {
        deleteToken();
        console.log(`Token refresh error: ${e}`);
        throw new Error();
    }

    // get new token
    try {
        const response = await authApiClient.mutate({
            mutation: REFRESH_TOKEN,
            variables: {
                refreshToken: refreshToken,
            }
        });
        const { token, refreshToken: newRefreshToken, payload } = response.data.refreshToken;

        setToken(token, payload);
        setRefreshToken(newRefreshToken);

        console.log(`...token refreshed`);

        return token;
    } catch (e) {
        // if (getToken())
        //     alert('Login session has expired! Please login again.');

        deleteToken();
        console.log(`Token refresh error: ${e.message}`);
        throw new Error(); // so that calling methods can handle appropriately
    }
}

// context hook
const AuthContext = createContext();
export const useAuth = () => React.useContext(AuthContext);

// AuthProvider component
export default function AuthProvider({ children }) {
    const [loading, setLoading] = useState(true);
    const [token, payload, setToken] = useToken();

    const apolloClient = useApolloClient();
    const [verifyToken] = useMutation(VERIFY_TOKEN, { client: authApiClient });

    useEffect(() => {
        if (!token) {
            apolloClient.clearStore();
        }
    }, [token]);

    // restore token
    useEffect(() => {
        const bootstrapAsync = async () => {
            let restoreToken = null;

            // fetch the token from storage
            try {
                console.log('checking storage for user token...');
                restoreToken = await Store.getItemAsync(TOKEN_KEY_STORE);
            } catch (e) {
                console.log('...error getting token from storage: ', e);
            }

            if (!restoreToken) {
                console.log('...no token found');
                setLoading(false);
                return;
            }

            // verify token
            try {
                console.log(`verifying token: ${restoreToken.slice(0, 4)}...${restoreToken.slice(-4)}...`);
                const response = await verifyToken({ variables: { token: restoreToken } });
                const { payload } = response.data.verifyToken;

                setToken(restoreToken, payload);
                console.log(`...user ${payload.username} logging in with token ${restoreToken.slice(0, 4)}...${restoreToken.slice(-4)}`);

                setLoading(false);
                return;
            } catch (e) {
                console.log('...could not verify token: ', e.message);
            }

            // refresh token
            try {
                await refreshTokenAsync();
            } catch (e) {
                console.log('...could not refresh token: ', e.message);
            }

            setLoading(false);
        };

        bootstrapAsync();
    }, []);

    return (
        <AuthContext.Provider value={{
            loading,
            userId: payload?.username,
            token,
        }}>
            {children}
        </AuthContext.Provider>
    );
}
