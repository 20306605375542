import React from 'react'
import { StyleSheet } from 'react-native'

import StyledText from './StyledText'

export default function H2({ children, style, ...props }) {
    return (
        <StyledText style={[styles.h2, style]} fontWeight={props.fontWeight || "bold"} {...props}>{children}</StyledText>
    )
}

const styles = StyleSheet.create({
    h2: {
        fontSize: 24,
        lineHeight: 32,
    }
})
