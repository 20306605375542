
import React from 'react';
import Svg, { Path, Rect } from 'react-native-svg';

export default function PinIcon({ size = 22, color = "currentColor", ...props }) {
    return (
        <Svg width={size} height={size} viewBox="0 0 22 22" fill="none" {...props}>
            <Rect width="22" height="22" fill="#353535" />
            <Path d="M10.9999 1.83334C6.95649 1.83334 3.66658 5.12326 3.66658 9.16209C3.64 15.07 10.7212 19.9687 10.9999 20.1667C10.9999 20.1667 18.3598 15.07 18.3332 9.16668C18.3332 5.12326 15.0433 1.83334 10.9999 1.83334ZM10.9999 12.8333C8.97408 12.8333 7.33324 11.1925 7.33324 9.16668C7.33324 7.14084 8.97408 5.50001 10.9999 5.50001C13.0257 5.50001 14.6666 7.14084 14.6666 9.16668C14.6666 11.1925 13.0257 12.8333 10.9999 12.8333Z" fill={color} />
        </Svg>
    );
}
