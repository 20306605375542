import React from 'react'
import { TextInput, StyleSheet, Platform } from 'react-native'

/**
 * Text input element with custom styles
 */

export default function StyledTextInput({ style, placeholderTextColor, textAlignVertical, inputRef, ...props }) {
    return (
        <TextInput
            style={[
                styles.input,
                props?.multiline && styles.inputMultiline,
                style,
            ]}
            placeholderTextColor={placeholderTextColor != undefined ? placeholderTextColor : '#8D8D8D'}
            textAlignVertical={textAlignVertical === undefined && props?.multiline ? 'top' : textAlignVertical}
            {...props}
            ref={inputRef}
        />
    )
}

const styles = StyleSheet.create({
    input: {
        width: '100%',
        paddingHorizontal: 14,
        paddingVertical: 10,
        fontSize: 17,
        height: 40,
        color: '#fff',
        backgroundColor: '#000',
        borderWidth: 1,
        borderColor: 'transparent',
        ...Platform.select({
            web: {
                outlineStyle: 'none',
            }
        })
    },
    inputMultiline: {
        paddingTop: 13,
        paddingBottom: 13,
    }
})
