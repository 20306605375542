import React from 'react';
import Svg, { Path } from 'react-native-svg';

export default function RightArrowIcon({ size = 16, color = "currentColor", ...props }) {
    return (
        <Svg width={size} height={size} viewBox="0 0 16 16" fill="none" {...props}>
            <Path fillRule="evenodd" clipRule="evenodd" d="M-3.49691e-07 8C-3.62938e-07 8.30305 0.118513 8.59369 0.329467 8.80798C0.540422 9.02227 0.826539 9.14265 1.12487 9.14265L12.1577 9.14265L7.32746 14.0469C7.22287 14.1531 7.13991 14.2793 7.08331 14.4181C7.02671 14.5569 6.99757 14.7057 6.99757 14.8559C6.99757 15.0062 7.02671 15.1549 7.08331 15.2937C7.13991 15.4325 7.22287 15.5587 7.32746 15.6649C7.43204 15.7711 7.55621 15.8554 7.69285 15.9129C7.8295 15.9704 7.97596 16 8.12387 16C8.27178 16 8.41823 15.9704 8.55488 15.9129C8.69153 15.8554 8.81569 15.7711 8.92028 15.6649L15.6695 8.809C15.7743 8.70286 15.8574 8.57676 15.9141 8.43794C15.9708 8.29912 16 8.1503 16 8C16 7.8497 15.9708 7.70088 15.9141 7.56206C15.8574 7.42324 15.7743 7.29715 15.6695 7.191L8.92028 0.335097C8.70906 0.120538 8.42258 -3.3122e-07 8.12387 -3.44277e-07C7.82516 -3.57334e-07 7.53868 0.120538 7.32746 0.335097C7.11624 0.549657 6.99757 0.840662 6.99757 1.14409C6.99757 1.44753 7.11624 1.73853 7.32746 1.95309L12.1577 6.85735L1.12487 6.85735C0.826539 6.85735 0.540422 6.97774 0.329467 7.19202C0.118513 7.40631 -3.36444e-07 7.69695 -3.49691e-07 8Z" fill={color} />
        </Svg>
    );
}
