import React from 'react'

import StyledText from './StyledText';

/**
 * Pressable is not inline. In order to use an "inline" Pressable we must use the
 * react native Text component.
 */

export default function PressableText({ children, style, onResponderGrant, onResponderRelease, ...props }) {
    const [pressed, setPressed] = React.useState(false);
    // hovered, focused, and active not currently supported for text links
    const [hovered, setHovered] = React.useState(false);
    const [focused, setFocused] = React.useState(false);
    const [active, setActive] = React.useState(false);

    const textOnResponderGrant = () => {
        onResponderGrant && onResponderGrant();
        setPressed(true);
    }
    const textOnResponderRelease = () => {
        onResponderRelease && onResponderRelease();
        setPressed(false);
    }

    const pressableStyle = [
        (typeof style === 'function') ? style({ pressed, hovered, active, focused }) : style,
    ]

    return (
        <StyledText
            style={pressableStyle}
            onResponderGrant={textOnResponderGrant}
            onResponderRelease={textOnResponderRelease}
            suppressHighlighting={true}
            {...props}
        >
            {children}
        </StyledText>
    )
}
