import React from 'react'
import { View, Text, StyleSheet } from 'react-native'
import Constants from 'expo-constants';
import * as Updates from 'expo-updates';
// import * as Clipboard from 'expo-clipboard';

import Container from '../components/Container';
import StyledText from '../components/StyledText';

export default function VersionScreen() {
    return (
        <Container style={styles.container}>
            <StyledText style={styles.item}>Channel: {Updates.channel}</StyledText>
            <StyledText style={styles.item}>Version: {Constants.expoConfig?.version}</StyledText>
            <StyledText style={styles.item}>Update ID: {Updates.updateId ? Updates.updateId : 'none'}</StyledText>
        </Container>
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        padding: 24,
    },
    item: {
        textAlign: 'center',
        marginBottom: 4,
    },
});
