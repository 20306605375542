import React from 'react'
import { ScrollView } from 'react-native-gesture-handler';

import WithScrollbar, { useScrollbarContext, useScrollbarProps } from './Scrollbar';

export default function StyledScrollView({ children, style, ...props }) {
    // const scrollBarContext = useScrollbarContext();

    return (
        <ScrollView style={style} showsVerticalScrollIndicator={false} {...props}>
            {children}
        </ScrollView>
        // <WithScrollbar scrollBarContext={scrollBarContext}>
        //     <ScrollView style={style} {...useScrollbarProps(scrollBarContext)} {...props}>
        //         {children}
        //     </ScrollView>
        // </WithScrollbar>
    )
}
