import React from 'react';
import Svg, { Path } from 'react-native-svg';

export default function JobSearchIcon({ size = 112, color = "currentColor", ...props }) {
    return (
        <Svg width={size} height={size} viewBox="0 0 112 112" fill="none" {...props}>
            <Path d="M41.8887 0H62.8331C65.6105 0 68.2741 1.10332 70.2381 3.06723C72.202 5.03115 73.3053 7.69479 73.3053 10.4722V20.9444H94.2497C97.0271 20.9444 99.6907 22.0477 101.655 24.0116C103.619 25.9755 104.722 28.6392 104.722 31.4166V66.6671C103.385 61.3846 98.4385 55.0313 94.2497 52.2038V31.4166H10.4722V89.0136H48.6023C50.2254 92.9406 53.8462 97.4615 57.6154 99.4858H10.4722C7.69479 99.4858 5.03115 98.3824 3.06723 96.4185C1.10332 94.4546 0 91.791 0 89.0136V31.4166C0 25.6045 4.66012 20.9444 10.4722 20.9444H31.4166V10.4722C31.4166 4.66012 36.0767 0 41.8887 0ZM62.8331 20.9444V10.4722H41.8887V20.9444H62.8331ZM95.8728 88.49L112 104.722L104.722 112L88.3852 95.9252C84.7723 98.1767 80.4787 99.4858 75.9233 99.4858C62.8331 99.4858 52.3609 89.0136 52.3609 75.9233C52.3609 62.8331 62.8331 52.3609 75.9233 52.3609C89.0136 52.3609 99.4858 62.8331 99.4858 75.9233C99.4858 80.5311 98.1767 84.8771 95.8728 88.49ZM75.9233 89.0136C79.3951 89.0136 82.7246 87.6344 85.1795 85.1795C87.6344 82.7246 89.0136 79.3951 89.0136 75.9233C89.0136 72.4516 87.6344 69.122 85.1795 66.6671C82.7246 64.2122 79.3951 62.8331 75.9233 62.8331C72.4516 62.8331 69.122 64.2122 66.6671 66.6671C64.2122 69.122 62.8331 72.4516 62.8331 75.9233C62.8331 79.3951 64.2122 82.7246 66.6671 85.1795C69.122 87.6344 72.4516 89.0136 75.9233 89.0136Z" fill={color} />
        </Svg>
    );
}
