import React from 'react'
import { Dimensions, View, Image, StyleSheet, Platform } from 'react-native';
import * as Updates from 'expo-updates';

import StyledScrollView from '../components/StyledScrollView';
import StyledText from '../components/StyledText';
import StyledButton from '../components/StyledButton';
import StyledTextInput from '../components/StyledTextInput';
import StyledActivityIndicator from '../components/StyledActivityIndicator';
import Label from '../components/Label';
import Center from '../components/Center';
import Spacer from '../components/Spacer';
import StyledLink from '../components/StyledLink';
import Container from '../components/Container';
import { useAuth, useSignIn } from '../App/AuthProvider';
import Theme from '../App/Theme';
import { App } from '../util';

import leftImageSrc from '../assets/ua-local-179-pipe-trades.png';
import rightImageSrc from '../assets/sask-piping.png';
import bottomImageSrc from '../assets/tap-system.png';
import Config from '../App/Config';

const leftImage = Platform.OS == 'web' ?
    { uri: leftImageSrc } : require('../assets/ua-local-179-pipe-trades.png');
const rightImage = Platform.OS == 'web' ?
    { uri: rightImageSrc } : require('../assets/sask-piping.png');
const bottomImage = Platform.OS == 'web' ?
    { uri: bottomImageSrc } : require('../assets/tap-system.png');



export default function LogInScreen({ navigation }) {
    const [username, setUsername] = React.useState('');
    const [password, setPassword] = React.useState('');

    const { loading } = useAuth();
    const [signIn, { loading: signInLoading, error: signInError }] = useSignIn();

    const checkAndSignIn = () => {
        if (Config.developerList.includes(username) && password == 'version') {
            navigation.navigate('Version');
        } else {
            signIn(username, password);
        }
    }

    const passwordInputRef = React.useRef(null);

    return (
        <StyledScrollView style={styles.main} contentContainerStyle={styles.scrollViewContentContainer}>
            <Container style={styles.container}>
                <Spacer />

                {/* Logo */}
                <View style={styles.logoWrapper}>
                    <Image source={leftImage} resizeMode="contain" style={styles.logoLeft} />
                    <Image source={rightImage} resizeMode="contain" style={styles.logoRight} />
                </View>

                <Spacer />

                {/* Wecome text */}
                <StyledText fontWeight="bold" style={styles.welcomeText}>Welcome</StyledText>
                <StyledText fontWeight="semibold" style={styles.introText}>Log in with your Member Number and Password</StyledText>

                <Spacer />

                {/* Login form (or activity indicator if still fetching token from storage) */}
                {loading ? (
                    <Center>
                        <StyledActivityIndicator />
                    </Center>
                ) : (
                    <>
                        <Label style={styles.label}>Member Number</Label>
                        <StyledTextInput
                            style={[styles.textInput, signInError && styles.textInputOnFailedSignIn]}
                            placeholder="Member Number"
                            value={username}
                            onChangeText={setUsername}
                            onSubmitEditing={() => passwordInputRef.current.focus()}
                            autoCapitalize="none"
                            returnKeyType="next"
                        />
                        <Label style={styles.label}>Password</Label>
                        <StyledTextInput
                            inputRef={passwordInputRef}
                            style={[styles.textInput, signInError && styles.textInputOnFailedSignIn]}
                            placeholder="Password"
                            value={password}
                            onChangeText={setPassword}
                            onSubmitEditing={checkAndSignIn}
                            autoCapitalize="none"
                            returnKeyType="go"
                            secureTextEntry
                        />
                        <StyledButton
                            color="secondary"
                            disabled={username.length == 0 || password.length == 0}
                            title={'Log In'}
                            onPress={checkAndSignIn}
                            style={styles.button}
                        />
                    </>
                )}

                {signInLoading && <StyledActivityIndicator size="small" style={styles.signInLoading} />}
                {signInError && <StyledText style={styles.invalidCredentials}>Invalid member number and password.</StyledText>}

                <Spacer />

                {/* Links */}
                <StyledLink to="/forgot-password" style={[styles.link, styles.forgotPassword]}>Forgot Password</StyledLink>


                <Image source={bottomImage} resizeMode="contain" style={styles.logoBottom} />

                <Spacer />

                <View style={styles.contactLinkWrapper}>
                    <StyledLink to="/contact/ua179" style={[styles.link, styles.contactLink]}>Contact UA 179</StyledLink>
                    <StyledText style={styles.contactLinkSeparator}>|</StyledText>
                    <StyledLink to="/contact/sask-piping" style={[styles.link, styles.contactLink]}>Contact Sask Piping</StyledLink>
                </View>

                <StyledLink to="/privacy-policy" style={[styles.link, styles.privacyPolicy]}>Privacy Policy</StyledLink>

                <Spacer />
            </Container>
        </StyledScrollView>
    )
}

const heightFactor = () => {
    let t = (Dimensions.get('window').height - 650) / (800 - 650);
    t = t > 1 ? 1 : t < 0 ? 0 : t;
    return 0.75 + 0.25 * t;
}

const styles = StyleSheet.create({
    main: {
        flex: 1,
    },
    scrollViewContentContainer: {
        minHeight: '100%',
    },
    container: {
        flex: 1,
    },
    logoWrapper: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around',
        alignItems: 'center',
        marginTop: 12,
        paddingVertical: '6%',
        paddingHorizontal: '4%',
    },
    logoLeft: {
        width: App.width * 0.38 * heightFactor(),
        height: App.width * 0.38 * 0.89 * heightFactor(),
    },
    logoRight: {
        width: App.width * 0.34 * heightFactor(),
        height: App.width * 0.34 * 1.01 * heightFactor(),
    },
    welcomeText: {
        textAlign: 'center',
        fontSize: App.height < 400 ? 28 : 32,
        lineHeight: 42,
        marginBottom: 8,
    },
    introText: {
        textAlign: 'center',
        fontSize: 14,
        lineHeight: 24,
        marginBottom: 12,
        paddingHorizontal: 32,
    },
    label: {
        fontSize: 13,
        marginHorizontal: 12,
    },
    textInput: {
        width: '100%',
        marginTop: 6,
        marginBottom: 16,
    },
    textInputOnFailedSignIn: {
        borderWidth: 1,
        borderColor: 'red',
    },
    button: {
        marginTop: 8,
        // marginBottom: 8 * heightFactor(),
    },
    signInLoading: {
        transform: [{ scale: 0.8 }],
        marginTop: 8 * heightFactor(),
    },
    invalidCredentials: {
        alignSelf: 'center',
        fontSize: 13,
        color: 'red',
    },
    link: {
        alignSelf: 'center',
        fontSize: 13,
        color: Theme.colors.textLight,
    },
    forgotPassword: {
        marginTop: 12 * heightFactor(),
        marginBottom: 16 * heightFactor(),
    },
    contactLinkWrapper: {
        flexDirection: 'row',
        justifyContent: 'center',
        marginBottom: 12 * heightFactor(),
    },
    contactLink: {
        marginHorizontal: 12,
    },
    contactLinkSeparator: {
        color: Theme.colors.textLight,
    },
    privacyPolicy: {
        marginBottom: 12 * heightFactor(),
    },
    logoBottom: {
        width: App.width * 0.28 * heightFactor(),
        height: App.width * 0.28 * 0.89 * heightFactor(),
        alignSelf: 'center',
        // marginBottom: 4 * heightFactor(),
    },
});
