import React from 'react';
import { StyleSheet, View } from 'react-native';
import { useMutation, gql } from '@apollo/client';

import Container from '../components/Container';
import StyledTextInput from '../components/StyledTextInput';
import StyledActivityIndicator from '../components/StyledActivityIndicator';
import Spacer from '../components/Spacer';
import StyledText from '../components/StyledText';
import H2 from '../components/H2';
import Label from '../components/Label';
import StyledLink from '../components/StyledLink';
import StyledButton from '../components/StyledButton';
import RightArrowIcon from '../icons/RightArrowIcon';
import StyledScrollView from '../components/StyledScrollView';

// mutation
const SET_PASSWORD = gql`
    mutation SetPassword($password1: String!,$password2: String!, $uidb64: String!, $token: String!) {
        setPassword(password1: $password1, password2: $password2, uidb64: $uidb64, token: $token) {
            ok
        }
    }
`;

export default function SetNewPasswordScreen({ route }) {
    const [password1, setPassword1] = React.useState('');
    const [password2, setPassword2] = React.useState('');
    const [inputError, setInputError] = React.useState(null);

    const { uidb64, token } = route.params;

    const [setPassword, { loading, error, data }] = useMutation(SET_PASSWORD, {
        variables: {
            password1,
            password2,
            uidb64,
            token,
        },
        onError: (error) => {
            console.log('Error setting new password:', error.message);
        },
    })

    const checkAndSetPassword = () => {
        setInputError(null);

        if (password1 != password2)
            setInputError('Passwords do not match!');
        else if (password1.length < 8)
            setInputError('Password must be at least 8 characters long!');
        else
            setPassword();
    }

    const password2InputRef = React.useRef(null);

    if (data) {
        return (
            <StyledScrollView style={styles.main}>
                <Container style={styles.container}>
                    <H2 style={styles.heading}>Success!</H2>
                    <StyledText style={styles.text}>Your password has been set.</StyledText>
                </Container>
            </StyledScrollView >
        )
    }

    return (
        <StyledScrollView style={styles.main}>
            <Container style={styles.container}>

                <H2 style={styles.heading}>Set Your password</H2>
                <StyledText style={styles.text}>Enter your new password below. Password must be 8 characters long.</StyledText>

                <Label style={styles.label1}>New Password:</Label>
                <StyledTextInput
                    style={[styles.textInput, inputError && styles.textInputOnError]}
                    value={password1}
                    placeholder="Password"
                    onChangeText={setPassword1}
                    onSubmitEditing={() => password2InputRef.current.focus()}
                    autoCapitalize="none"
                    returnKeyType="next"
                    secureTextEntry
                />
                <Label style={styles.label2}>Confirm Password:</Label>
                <StyledTextInput
                    inputRef={password2InputRef}
                    style={[styles.textInput, inputError && styles.textInputOnError]}
                    value={password2}
                    placeholder="Password"
                    onChangeText={setPassword2}
                    onSubmitEditing={checkAndSetPassword}
                    autoCapitalize="none"
                    returnKeyType="next"
                    secureTextEntry
                />
                <StyledButton color="secondary" icon={RightArrowIcon} onPress={checkAndSetPassword} style={styles.submitButton}>Submit</StyledButton>
                {loading && <StyledActivityIndicator size="small" style={styles.loadingIndicator} />}
                {inputError && <StyledText style={styles.error}>{inputError}</StyledText>}
                {error && <StyledText style={styles.error}>Could not reset password!</StyledText>}

            </Container>
        </StyledScrollView >
    )
}

const styles = StyleSheet.create({
    main: {
        flex: 1,
    },
    container: {
        flex: 1,
        paddingVertical: 8,
    },
    heading: {
        marginTop: 16,
    },
    text: {
        marginTop: 8,
    },
    label1: {
        fontSize: 13,
        marginTop: 32,
        marginHorizontal: 12,
    },
    label2: {
        fontSize: 13,
        marginTop: 16,
        marginHorizontal: 12,
    },
    textInput: {
        width: '100%',
        marginTop: 6,
    },
    textInputOnError: {
        borderWidth: 1,
        borderColor: 'red',
    },
    submitButton: {
        marginTop: 22,
        marginBottom: 8,
    },
    loadingIndicator: {

    },
    error: {
        alignSelf: 'center',
        color: 'red',
    },
});
