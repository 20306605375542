import React from 'react';
import { StyleSheet, View, RefreshControl } from 'react-native';
import { useQuery, gql, NetworkStatus } from '@apollo/client';

import StyledFlatList from '../components/StyledFlatList';
import LinkListItem from '../components/LinkListItem';
import Loading from '../components/Loading';
import Error from '../components/Error';
import NoResults from '../components/NoResults';

// query
const COURSES_QUERY = gql`
    query Courses {
        courses {
            id
            title
        }
    }
`;

export default function CoursesScreen({ navigation }) {
    const { loading, error, data, refetch, networkStatus } = useQuery(COURSES_QUERY);

    if (loading && networkStatus !== NetworkStatus.refetch) {
        return (
            <Loading />
        )
    }

    if (error) {
        console.log('Error loading courses: ', error.message);

        return (
            <Error />
        )
    }

    return (
        <View style={styles.container}>
            <StyledFlatList
                data={data.courses}
                keyExtractor={(course) => String(course.id)}
                renderItem={({ item }) => <LinkListItem title={item.title} to={{ screen: "CourseDetails", params: { courseId: item.id } }} />}
                refreshControl={
                    <RefreshControl
                        refreshing={loading}
                        onRefresh={() => { console.log('refetching data'); refetch() }}
                        title=""
                        tintColor="#999999"
                    />
                }
                ListHeaderComponent={() => {
                    if (!loading && data?.courses.length === 0) {
                        return (
                            <NoResults
                                title="No courses found."
                            />
                        );
                    }
                    return null
                }}
                directionalLockEnabled={true}
            />
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
    },
});
