import React from 'react'
import { View, StyleSheet } from 'react-native'

import Theme from '../App/Theme';

/**
 * Adds a scrollbar to the view if the content doesnt fit
 */
export default function WithScrollbar({ scrollBarContext, children }) {
    const { contentOffset, contentSize, scrollViewHeight } = scrollBarContext;

    if (contentSize <= scrollViewHeight)
        return children;

    const scrollElementHeightPercent = scrollViewHeight / contentSize * 100;
    const scrollPerc = (contentOffset.y / (contentSize - scrollViewHeight))
        * (100 - scrollElementHeightPercent);

    return (
        <View
            style={styles.wrapper}
        >
            {children}
            <View style={styles.scrollbar}>
                <View
                    style={[styles.scrollbarThumb, {
                        top: `${Number(scrollPerc || 0).toFixed(0)}%`,
                        height: `${scrollElementHeightPercent}%`,
                    }]}
                />
            </View>
        </View>
    )
}

const styles = StyleSheet.create({
    wrapper: {
        flex: 1,
        flexDirection: 'row',
        position: 'relative',
    },
    scrollbar: {
        width: 0,
    },
    scrollbarThumb: {
        position: "absolute",
        right: 0,
        width: 6,
        borderRadius: 3,
        backgroundColor: Theme.colors.card,
    }
})

// optional provider (not used)
export function ScrollbarProvider({ children }) {
    const context = useScrollbarContext();

    return (
        <ScrollbarContext.Provider value={context}>
            {children}
        </ScrollbarContext.Provider>
    )
}

export const useScrollbarContext = () => {
    const [contentOffset, setContentOffset] = React.useState({ x: 0, y: 0 });
    const [contentSize, setContentSize] = React.useState(0);
    const [scrollViewHeight, setScrollViewHeight] = React.useState(0);

    // it seems onLayout and onContentSizeChange will get called after the ScrollView unmounts and so 
    // we must check before setting the state
    const [isMounted, setIsMounted] = React.useState(false);

    React.useEffect(() => {
        setIsMounted(true);

        return () => setIsMounted(false)
    }, [])

    return {
        contentOffset,
        setContentOffset,
        contentSize,
        setContentSize,
        scrollViewHeight,
        setScrollViewHeight,
        isMounted
    }
}

export const useScrollbarProps = (scrollBarContext) => {
    const { setContentOffset, setContentSize, setScrollViewHeight, isMounted } = scrollBarContext;

    const onScroll = (e) => {
        if (isMounted)
            setContentOffset(e.nativeEvent.contentOffset);
    };
    const onContentSizeChange = (_, height) => {
        if (isMounted)
            setContentSize(height);
    };
    const onLayout = (e) => {
        if (isMounted)
            setScrollViewHeight(e.nativeEvent.layout.height);
    };

    return { onScroll, onContentSizeChange, onLayout, showsVerticalScrollIndicator: false, scrollEventThrottle: 16 };
}
