import React from 'react';
import Svg, { Line } from 'react-native-svg';

export default function XIcon({ size = 24, color = "currentColor", ...props }) {
    return (
        <Svg width={size} height={size} fill="none" stroke={color} strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" {...props}>
            <Line x1="18" x2="6" y1="6" y2="18" />
            <Line x1="6" x2="18" y1="6" y2="18" />
        </Svg>
    );
}
