import React from 'react'
import { View, Text, StyleSheet } from 'react-native'
import Constants from 'expo-constants';
import * as Updates from 'expo-updates';
// import * as Clipboard from 'expo-clipboard';

import Container from '../components/Container';
import StyledFlatList from '../components/StyledFlatList';
import StyledText from '../components/StyledText';
import OpenRightIcon from '../icons/OpenRightIcon';
import Theme from '../App/Theme';
import { clear, getLogs, isDebug } from '../util/debug';
import StyledLink from '../components/StyledLink';
import Separator from '../components/Separator';

export default function DebugScreen() {
    const [_, update] = React.useState(null);

    return (
        <Container style={styles.container}>
            <StyledFlatList
                data={getLogs()}
                keyExtractor={(item, index) => String(index)}
                renderItem={({ item }) => (
                    <View style={styles.message}>
                        <OpenRightIcon size={10} color="white" style={styles.caret} />
                        <StyledText selectable style={[styles.messageText, styles[item.level]]}>{item.message}</StyledText>
                    </View>
                )}
                ListHeaderComponent={() => (
                    <View style={styles.header}>
                        <StyledText fontWeight="semibold" style={styles.headerItem}>App Name: {Constants.expoConfig?.name}</StyledText>
                        <StyledText fontWeight="semibold" style={styles.headerItem}>Version: {Constants.expoConfig?.version}</StyledText>
                        <StyledText fontWeight="semibold" style={styles.headerItem}>SDK Version: {Constants.expoConfig?.sdkVersion}</StyledText>

                        <StyledText fontWeight="semibold" style={styles.headerItem}>Update Info:</StyledText>
                        <StyledText style={styles.headerSubItem}>Channel: {Updates.channel}</StyledText>
                        {/* <StyledText style={styles.headerSubItem}>Group ID: <StyledText style={styles.headerTextSmall}>{Constants.manifest2?.metadata?.["updateGroup"]}</StyledText></StyledText> */}
                        <StyledText style={styles.headerSubItem}>Update ID: <StyledText style={styles.headerTextSmall}>{Updates.updateId}</StyledText></StyledText>
                        <StyledText style={styles.headerSubItem}>Created At: {new Date(Constants.manifest2?.createdAt).toLocaleString()}</StyledText>
                        <StyledText style={styles.headerSubItem}>Embedded Launch: {Updates.isEmbeddedLaunch ? 'true' : 'false'}</StyledText>
                        <StyledText style={styles.headerSubItem}>Emergency Launch: {Updates.isEmergencyLaunch ? 'true' : 'false'}</StyledText>
                    </View>
                )}
                ListFooterComponent={() => (
                    <>
                        {/* <StyledLink onPress={() => Clipboard.setString(logsToString(getLogs()))} style={styles.copyToClipboard}>Copy to Clipboard</StyledLink> */}
                        <StyledLink onPress={() => { clear(); update() }} style={styles.clear}>Clear</StyledLink>
                    </>)}
            />
        </Container>
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
    },
    header: {
        marginTop: 16,
        marginBottom: 24,
    },
    headerItem: {
        // textAlign: 'center',
    },
    headerSubItem: {
        paddingLeft: 12,
    },
    headerTextSmall: {
        fontSize: 10,
    },
    caret: {
        marginTop: 2,
        marginRight: 8,
    },
    message: {
        flex: 1,
        flexDirection: 'row',
    },
    messageText: {
        flex: 1,
        lineHeight: 16,
        marginBottom: 4,
    },
    info: {
    },
    warn: {
        color: 'yellow',
    },
    error: {
        color: 'red',
    },
    copyToClipboard: {
        alignSelf: 'center',
        marginVertical: 4,
        color: Theme.colors.link,
    },
    clear: {
        alignSelf: 'center',
        marginVertical: 4,
        color: Theme.colors.link,
    },
});
