import React, { useEffect } from 'react';
import { Platform, StyleSheet } from 'react-native';
import { useQuery, useMutation, gql } from '@apollo/client';

import StyledScrollView from '../components/StyledScrollView';
import StyledText from '../components/StyledText';
import StyledTextInput from '../components/StyledTextInput';
import H2 from '../components/H2';
import Label from '../components/Label';
import Separator from '../components/Separator';
import Container from '../components/Container';
import StyledButton from '../components/StyledButton';
import Center from '../components/Center';
import StyledActivityIndicator from '../components/StyledActivityIndicator';
import RightArrowIcon from '../icons/RightArrowIcon';
import StyledLink from '../components/StyledLink';

// query
const COURSE_QUERY = gql`
    query Course($courseId: Int) {
        course(id: $courseId) {
            id
            title
            description
            timeEstimate
            courseCategory {
                id
                title
            }
            link
        }
    }
`;

export default function CourseDetailsScreen({ route, navigation }) {
    const [message, setMessage] = React.useState('');

    const { courseId } = route.params;

    const { loading, error, data } = useQuery(COURSE_QUERY, {
        variables: {
            courseId,
        }
    });

    useEffect(() => {
        if (data && Platform.OS == 'web')
            navigation.setOptions({ documentTitle: data.course.title });
    });

    if (loading) {
        return (
            <Center>
                <StyledActivityIndicator />
            </Center>
        )
    }

    if (error) {
        return (
            <Center>
                <StyledText>Could not load course!</StyledText>
            </Center>
        )
    }

    return (
        <StyledScrollView style={styles.wrapper}>
            <Container style={styles.container}>
                <Label style={styles.label}>Course Name</Label>
                <H2 style={styles.courseName}>{data.course.title}</H2>
                <Label style={styles.label}>Time Estimate</Label>
                <StyledText size="large" fontWeight="semibold" style={styles.timeEstimate}>{data.course.timeEstimate}</StyledText>
            </Container>
            <Separator />
            <Container style={styles.container}>
                <Label style={[styles.label, styles.detailsLabel]}>Details</Label>
                <StyledText style={styles.courseDetails} fontWeight="semibold">{data.course.description}</StyledText>
            </Container>
            <Separator />
            <Container style={styles.container}>
                <Label style={styles.label}>More Info</Label>
                <StyledLink fontWeight="semibold" href={data.course.link} style={styles.link}>{data.course.link}</StyledLink>
                {/* <StyledText size="large" fontWeight="semibold" style={styles.registrationRequest}>Request Registration</StyledText>
                <StyledTextInput
                    style={styles.sendRequestMessage}
                    placeholder="Compose a message (Optional)"
                    value={message}
                    onChangeText={setMessage}
                    multiline={true}
                    numberOfLines={4}
                />
                <StyledButton color="secondary" icon={RightArrowIcon} onPress={sendRequest} style={styles.sendRequestButton}>Send Request</StyledButton>
                {sendRequestLoading && <StyledActivityIndicator style={styles.sendRequestStatus} />}
                {sendRequestResponse?.addCourseRegistration && <StyledText style={styles.sendRequestStatus}>Request sent!</StyledText>}
                {sendRequestError && <StyledText style={[styles.sendRequestStatus, { color: 'red' }]}>Error sending request!</StyledText>} */}
            </Container>
        </StyledScrollView>
    )
}

const styles = StyleSheet.create({
    wrapper: {
        flex: 1,
    },
    container: {
        marginVertical: 16,
    },
    label: {
        marginBottom: 4,
    },
    courseName: {
        marginBottom: 18,
    },
    timeEstimate: {
    },
    detailsLabel: {
        marginBottom: 8,
    },
    courseDetails: {
        marginBottom: 10,
    },
    registrationRequest: {
        marginBottom: 14,
    },
    sendRequestMessage: {
        height: 150,
    },
    sendRequestButton: {
        marginTop: 20,
    },
    sendRequestStatus: {
        marginTop: 12,
        textAlign: 'center'
    }
});
