import * as Updates from 'expo-updates';

const DEV_GRAPHQL_URI = 'https://ua-local-179-backend-dev-rqowc.ondigitalocean.app/graphql';
const GRAPHQL_URI = 'https://admin.ualocal179.ca/graphql';
const WEB_URL = 'https://app.ualocal179.ca';

let Config = {
    graphqlUri: GRAPHQL_URI,
    webUrl: WEB_URL,
    developerList: [
        'jteichro',
        'jteichroeb',
    ],
    debug: false,
};

switch (Updates.channel) {
    case 'development':
    case 'test':
        //Config.graphqlUri = DEV_GRAPHQL_URI;
        Config.webUrl = WEB_URL;
        Config.debug = true;
        break;
    case 'preview':
    case 'production':
        Config.graphqlUri = GRAPHQL_URI;
        Config.webUrl = WEB_URL;
        Config.debug = false;
        break;
}

// for web
switch (process.env.NODE_ENV) {
    case 'development':
        //Config.graphqlUri = DEV_GRAPHQL_URI;
        Config.webUrl = WEB_URL;
        Config.debug = true;
        break;
    case 'production':
        Config.graphqlUri = GRAPHQL_URI;
        Config.webUrl = WEB_URL;
        Config.debug = false;
        break;
}

export default Config;
