import React from 'react'
import { View, Share, StyleSheet, Platform } from 'react-native'
import * as Clipboard from 'expo-clipboard';

import StyledButton from './StyledButton';
import StyledText from './StyledText';
import ShareIcon from '../icons/ShareIcon';

/**
 * Button that handles the sharing logic.
 */
export default function ShareButton({ children, content, title = 'Share', color = null, ...props }) {
    if (!content)
        console.warn('Missing content in share button!');

    const { linkCopiedToClipboard, onShare } = useShareCallback(content);

    return (
        <View {...props}>
            <StyledButton title={title} color={color} onPress={onShare} icon={ShareIcon}>{children}</StyledButton>
            {linkCopiedToClipboard && <StyledText style={styles.linkCopiedText}>Link copied to clipboard.</StyledText>}
        </View>
    );
}

export const useShareCallback = (content, title = 'Share') => {
    const [linkCopiedToClipboard, setLinkCopiedToClipboard] = React.useState(false);

    const onShare = React.useCallback(async () => {
        if (Platform.OS == 'web') {
            await Clipboard.setStringAsync(content);
            setLinkCopiedToClipboard(true);
        } else {
            await Share.share({
                message: content,
                title,
            });
        }
    }, [content, title])

    return { linkCopiedToClipboard, onShare }
}

const styles = StyleSheet.create({
    linkCopiedText: {
        marginTop: 4,
        marginLeft: 8,
    },
})
