import React from 'react';
import { Dimensions, Image, Platform, StyleSheet, View } from 'react-native';
import { useQuery, gql } from '@apollo/client';

import StyledScrollView from '../components/StyledScrollView';
import StyledText from '../components/StyledText';
import StyledButton from '../components/StyledButton';
import Label from '../components/Label';
import MailIcon from '../icons/MailIcon';
import PhoneIcon from '../icons/PhoneIcon';
import FaxIcon from '../icons/FaxIcon';
import PinIcon from '../icons/PinIcon';
import Container from '../components/Container';
import StyledLink from '../components/StyledLink';
import Loading from '../components/Loading';
import Error from '../components/Error';

import Config from '../App/Config';
import H2 from '../components/H2';
import Separator from '../components/Separator';
import { openMap } from '../util/map';
import { App } from '../util';

import ua179LogoSrc from '../assets/ua-local-179-pipe-trades.png';
import saskPipingLogoSrc from '../assets/sask-piping.png';

// data
const CONTACT_INFO = {
    'ua179': {
        title: 'Contact UA 179',
        logo: Platform.OS == 'web' ?
            { uri: ua179LogoSrc } : require('../assets/ua-local-179-pipe-trades.png'),
        website: {
            label: 'ualocal179.ca',
            url: 'https://ualocal179.ca'
        },
        locations: [
            {
                title: 'UA 179 Office - Regina',
                links: [
                    {
                        title: 'Email',
                        value: 'mail@ualocal179.ca',
                        type: 'email',
                        primary: true,
                    },
                    {
                        title: 'Phone',
                        value: '306-569-0624',
                        type: 'tel',
                        primary: true,
                    },
                    {
                        title: 'Job Call Out (Toll Free)',
                        value: '877-893-2179',
                        sublabel: '(Sask only)',
                        type: 'tel',
                        primary: true,
                    },
                    {
                        title: 'Job Call Out',
                        value: '306-569-3641',
                        type: 'tel',
                        primary: false,
                    },
                    {
                        title: 'Address',
                        value: '402 Solomon Drive, Regina, SK  S4N 5A8',
                        type: 'address',
                        primary: false,
                    },
                ]
            },
            {
                title: 'UA 179 Office - Saskatoon',
                links: [
                    {
                        title: 'Phone',
                        value: '306-956-1061',
                        type: 'tel',
                        primary: true,
                    },
                    {
                        title: 'Address',
                        value: '334 Robin Way, Saskatoon, SK  S7L 6X4',
                        type: 'address',
                        primary: false,
                    },
                ]
            },
        ],
        people: [
            {
                title: 'Business Manager',
                name: 'Mike McLean',
                links: [
                    {
                        title: 'Email',
                        value: 'mike@ualocal179.ca',
                        type: 'email'
                    },
                    {
                        title: 'Phone',
                        value: '306-569-0624',
                        type: 'tel'
                    },
                ]
            },
            {
                title: 'Industrial Business Agent',
                name: 'Brandon Faul',
                links: [
                    {
                        title: 'Email',
                        value: 'brandon@ualocal179.ca',
                        type: 'email'
                    },
                    {
                        title: 'Phone',
                        value: '306-569-0624',
                        type: 'tel'
                    },
                ]
            },
            {
                title: 'Commercial Business Agent',
                name: 'Mitch Grenier',
                links: [
                    {
                        title: 'Email',
                        value: 'MGrenier@ualocal179.ca',
                        type: 'email'
                    },
                    {
                        title: 'Phone',
                        value: '306-956-1061',
                        type: 'tel'
                    },
                ]
            },
        ]
    },
    'sask-piping': {
        title: 'Contact Sask Piping',
        logo: Platform.OS == 'web' ?
            { uri: saskPipingLogoSrc } : require('../assets/sask-piping.png'),
        website: {
            label: 'saskpiping.ca',
            url: 'http://www.saskpiping.ca/'
        },
        locations: [
            {
                title: 'Sask Piping Industry - Regina',
                links: [
                    {
                        title: 'Email',
                        value: 'inquiries@saskpiping.ca',
                        type: 'email',
                        primary: true,
                    },
                    {
                        title: 'Phone',
                        value: '306-522-4237',
                        type: 'tel',
                        primary: true
                    },
                    {
                        title: 'Address',
                        value: '402 Solomon Drive, Regina, SK  S4N 5A8',
                        type: 'address',
                    },
                ]
            },
            {
                title: 'Sask Piping Industry - Saskatoon',
                links: [
                    {
                        title: 'Email',
                        value: 'inquiries@saskpiping.ca',
                        type: 'email',
                        primary: true,
                    },
                    {
                        title: 'Phone',
                        value: '306-651-3737',
                        type: 'tel',
                        primary: true
                    },
                    {
                        title: 'Fax',
                        value: '306-651-3466',
                        type: 'fax',
                    },
                    {
                        title: 'Address',
                        value: '334 Robin Way, Saskatoon, SK  S7L 6X4',
                        type: 'address',
                    },
                ]
            },
        ],
        people: [
            {
                title: 'Director Of Training',
                name: 'Brad Funk',
                links: [
                    {
                        title: 'Email',
                        value: 'b.funk@saskpiping.ca',
                        type: 'email'
                    },
                    {
                        title: 'Phone',
                        value: '306-651-3737',
                        type: 'tel'
                    },
                ]
            },
        ]
    },
}

const getLinkProps = (value, type) => {
    switch (type) {
        case 'email':
            return {
                href: `mailto:${value}`,
                icon: MailIcon,
            };
        case 'tel':
            return {
                href: `tel:${value}`,
                icon: PhoneIcon,
            };
        case 'fax':
            return {
                href: `tel:${value}`,
                icon: FaxIcon,
            };
        case 'address':
            return {
                onPress: () => openMap(value),
                icon: PinIcon,
            };
        default: return {};
    }
}

export default function ContactScreen({ route, navigation }) {
    const { officeLocation } = route.params;

    React.useEffect(() => {
        navigation.setOptions({ title: CONTACT_INFO[officeLocation].title })
    }, [navigation, officeLocation])

    return (
        <StyledScrollView style={styles.main}>

            <Container style={styles.container}>
                <View style={styles.logoWrapper}>
                    <Image source={CONTACT_INFO[officeLocation].logo} resizeMode="contain" style={styles.logo} />
                </View>

                <View style={styles.introWrapper}>
                    <StyledText fontWeight="semibold" style={styles.introText} >Reach out to us directly for more info about courses, or to sign up for a membership.</StyledText>
                </View>
            </Container>

            {CONTACT_INFO[officeLocation]?.locations?.map(({ title, links }, index) => (
                <View key={index}>
                    {index !== 0 && (
                        <Separator style={styles.separator} />
                    )}
                    <Container style={[styles.container, styles.officesContainer]}>
                        {!!title && (
                            <H2 style={styles.title}>{title}</H2>
                        )}
                        {!!links && links.map(({ title, value, sublabel, type, primary }, index) => (
                            <View key={index}>
                                <Label style={styles.label}>{title}</Label>
                                <StyledButton color={primary ? 'secondary' : null} style={styles.button} {...getLinkProps(value, type)}>{value} {sublabel ? <StyledText style={styles.buttonSublabel}>{sublabel}</StyledText> : ''}</StyledButton>
                            </View>
                        ))}
                    </Container>
                </View>
            ))}

            {CONTACT_INFO[officeLocation]?.people?.map(({ title, name, links }, index) => (
                <View key={index}>
                    <Separator style={styles.staffSeparator} />
                    <Container style={[styles.container, styles.staffContainer]}>
                        {!!title && (
                            <StyledText style={styles.staffTitle}>{title}</StyledText>
                        )}
                        {!!name && (
                            <StyledText fontWeight="semibold" style={styles.staffName}>{name}</StyledText>
                        )}
                        {!!links && links.map(({ title, value, type }, index) => (
                            <StyledText key={index} style={styles.staffInfo}>{title}: <StyledLink href={getLinkProps(value, type)?.href}>{value}</StyledLink></StyledText>
                        ))}
                    </Container>
                </View>
            ))}

            <StyledLink href={CONTACT_INFO[officeLocation].website.url} style={styles.bottomUrl}>{CONTACT_INFO[officeLocation].website.label}</StyledLink>
        </StyledScrollView>
    )
}

const styles = StyleSheet.create({
    main: {
        flex: 1,
        marginBottom: 12,
    },
    logoWrapper: {
        marginTop: 12,
        paddingVertical: '6%',
        paddingHorizontal: '4%',
    },
    logo: {
        alignSelf: 'center',
        width: App.width * 0.38,
        height: App.width * 0.38 * 0.89,
    },
    icon: {
        marginTop: 48,
        marginBottom: 16,
    },
    introWrapper: {
        alignItems: 'center',
        paddingHorizontal: 16,
    },
    introText: {
        marginBottom: 24,
        textAlign: 'center',
    },
    officesContainer: {
        marginVertical: 20,
    },
    separator: {
    },
    title: {
        marginBottom: 4,
    },
    label: {
        marginBottom: 2,
    },
    button: {
        marginBottom: 4,
    },
    buttonSublabel: {
        fontSize: 14,
    },
    staffContainer: {
    },
    staffSeparator: {
        marginVertical: 12,
    },
    staffTitle: {
    },
    staffName: {
        fontSize: 20,
        marginTop: 8,
        marginBottom: 8,
    },
    staffInfo: {
        fontSize: 13,
    },
    bottomUrl: {
        color: '#9E9E9E',
        textAlign: 'center',
        marginVertical: 48,
    }
});
