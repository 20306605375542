import React from 'react';
import { StyleSheet, View } from 'react-native';
import { gql, useLazyQuery } from '@apollo/client';

import StyledFlatList from '../components/StyledFlatList';
import Container from '../components/Container';
import StyledTextInput from '../components/StyledTextInput';
import StyledText from '../components/StyledText';
import SearchIcon from '../icons/SearchIcon';
import SearchLargeIcon from '../icons/SearchLargeIcon';
import OpenRightIcon from '../icons/OpenRightIcon';
import PlusCircledIcon from '../icons/PlusCircledIcon';

import Pressable from '../components/Pressable';
import Loading from '../components/Loading';
import NoResults from '../components/NoResults';
import Theme from '../App/Theme';
import { useLinkStyles } from '../components/StyledLink';



// query
const SEARCH_QUERY = gql`
    query MemberSearch($searchQuery: String) {
        member(username: $searchQuery) {
            id
            username
            firstName
            lastName
        }
    }
`;

function ClearTextButton(props) {
    return (
        <Pressable {...props}>
            <PlusCircledIcon color="#BEBEBE" />
        </Pressable>
    )
}

function Separator() {
    return (
        <View style={styles.resultItemSeparator} />
    )
}

let lastTabPress = 0;
const DOUBLE_TAB_PRESS_DELAY = 400;

export default function SearchScreen({ navigation }) {
    const [searchQuery, setSearchQuery] = React.useState("");

    const [search, { loading, error, data }] = useLazyQuery(SEARCH_QUERY);

    const inputRef = React.useRef(null);

    // focus search input on double tap tab
    React.useEffect(() => {
        const unsubscribe = navigation.getParent().addListener('tabPress', (e) => {
            const time = new Date().getTime();
            if (time - lastTabPress < DOUBLE_TAB_PRESS_DELAY)
                inputRef.current.focus();

            lastTabPress = time;
        });

        return unsubscribe;
    }, [navigation]);

    return (
        // <TouchableWithoutFeedback onPress={() => inputRef.current.blur()}>
        // <View >
        <StyledFlatList style={styles.main}
            data={loading || error || !data ? [] : [data.member]}
            keyExtractor={(member) => String(member.id)}
            onResponderGrant={() => inputRef.current.blur()}
            renderItem={({ item, index }) => (
                <>
                    {index == 0 && <Separator />}
                    <Pressable onPress={() => navigation.navigate("UserProfile", { profileId: item.username })} style={styles.resultItem}>
                        <StyledText fontWeight="semibold" numberOfLines={1} style={styles.resultItemTitle}>{item.firstName} {item.lastName}</StyledText>
                        <StyledText numberOfLines={1} style={styles.resultItemSubtitle}>{item.username}</StyledText>
                        <OpenRightIcon style={styles.resultItemIcon} />
                    </Pressable>
                    <Separator />
                </>
            )}
            ListHeaderComponent={(
                <Container>
                    <View style={styles.searchFormWrapper}>
                        <SearchIcon size={16} style={styles.searchFormIcon} />
                        <StyledTextInput
                            inputRef={inputRef}
                            value={searchQuery}
                            onChangeText={setSearchQuery}
                            onSubmitEditing={() => search({ variables: { searchQuery } })}
                            placeholder="Search"
                            placeholderTextColor="#8E8E93"
                            autoCapitalize="none"
                            autoComplete="off"
                            autoCorrect={false}
                            returnKeyType="search"
                            style={styles.searchForm}
                        />
                        {searchQuery.length > 0 && <ClearTextButton onPress={() => setSearchQuery("")} style={useLinkStyles(styles.searchFormClearButton)} />}
                    </View>
                </Container>
            )}
            ListEmptyComponent={(
                <>
                    {loading ? (
                        <Loading />
                    ) : error ? (
                        <NoResults title="No Results" /> // error actually means no results
                    ) : !data ? (
                        <NoResults Icon={SearchLargeIcon} title="Search for another member by entering their Member Number above." />
                    ) : (
                        <NoResults title="No Results" />
                    )}
                </>
            )}
            directionalLockEnabled={true}
        />
        // </View>
        // </TouchableWithoutFeedback>
    )
}

const styles = StyleSheet.create({
    main: {
        flex: 1,
        marginBottom: 24,
    },
    searchFormWrapper: {
        position: 'relative',
        flexDirection: 'row',
        alignItems: 'center',
        marginVertical: 24,
    },
    searchForm: {
        flex: 1,
        fontSize: 17,
        height: 42,
        backgroundColor: '#333337',
        borderRadius: 13,
        color: 'white',
        paddingHorizontal: 40, // 14
        paddingVertical: 10,
    },
    searchFormIcon: {
        position: 'absolute',
        left: 14,
        zIndex: 1,
        color: '#8E8E93',
        lineHeight: 16,
    },
    searchFormClearButton: {
        position: 'absolute',
        right: 14,
        zIndex: 1,
        height: 16,

    },
    loadingIndicator: {
        alignSelf: 'center',
        marginVertical: 24,
    },
    resultItemSeparator: {
        marginLeft: Theme.container.padding,
        borderBottomWidth: 1,
        borderColor: Theme.colors.border,
    },
    resultItem: {
        flexDirection: 'row',
        alignItems: 'center',
        paddingVertical: 24,
        paddingHorizontal: Theme.container.padding,
    },
    resultItemTitle: {
        flex: 1,
        fontSize: 18,
        lineHeight: 24,
        marginLeft: 2,
    },
    resultItemSubtitle: {
        fontSize: 14,
        lineHeight: 20,
        color: '#A1A1A9',
        marginLeft: 20,
    },
    resultItemIcon: {
        color: '#616166',
        marginLeft: 20,
    },
});
