
import React from 'react';
import Svg, { Path } from 'react-native-svg';

export default function PhoneIcon({ size = 22, color = "currentColor", ...props }) {
    return (
        <Svg width={size} height={size} viewBox="0 0 22 22" fill="none" {...props}>
            <Path d="M9.30115 12.6133C10.5768 13.9074 12.1204 14.9066 13.8234 15.5406L16.1273 13.7072C16.1956 13.6602 16.2765 13.635 16.3595 13.635C16.4424 13.635 16.5234 13.6602 16.5917 13.7072L20.8695 16.4633C21.0319 16.561 21.1694 16.695 21.271 16.8549C21.3727 17.0148 21.4359 17.1962 21.4554 17.3847C21.475 17.5731 21.4504 17.7636 21.3837 17.941C21.3171 18.1183 21.21 18.2778 21.0712 18.4067L19.0667 20.3867C18.7796 20.6703 18.4268 20.8785 18.0398 20.9926C17.6527 21.1066 17.2434 21.1231 16.8484 21.0406C12.9081 20.2268 9.27618 18.3208 6.36782 15.5406C3.524 12.7327 1.55609 9.15985 0.702815 5.25556C0.618522 4.86667 0.636115 4.4626 0.753889 4.08251C0.871664 3.70242 1.08562 3.3592 1.37504 3.08611L3.45282 1.08167C3.58139 0.949538 3.7382 0.848208 3.91148 0.785265C4.08477 0.722322 4.27005 0.699398 4.45346 0.718209C4.63686 0.737019 4.81363 0.797077 4.97053 0.893885C5.12743 0.990694 5.2604 1.12174 5.35948 1.27722L8.20726 5.5C8.25638 5.56631 8.28289 5.64665 8.28289 5.72917C8.28289 5.81169 8.25638 5.89203 8.20726 5.95833L6.33115 8.21334C6.98234 9.88224 7.99687 11.3853 9.30115 12.6133Z" fill={color} />
        </Svg>
    );
}
