import React from 'react';
import { StyleSheet, View, Image } from 'react-native';
import * as Linking from 'expo-linking';
import { gql, useQuery } from '@apollo/client';

import StyledScrollView from '../components/StyledScrollView';
import Label from '../components/Label';
import StyledText from '../components/StyledText';
import Center from '../components/Center';
import Separator from '../components/Separator';
import LinkListItem from '../components/LinkListItem';
import Container from '../components/Container';
import StyledButton from '../components/StyledButton';
import StyledActivityIndicator from '../components/StyledActivityIndicator';
import ShareIcon from '../icons/ShareIcon';
import EditIcon from '../icons/EditIcon';
import Pressable from '../components/Pressable';

import Theme from '../App/Theme';
import { useShareCallback } from '../components/ShareButton';
import { createShareableLink, useApp } from '../util';

// query
const MEMBER_QUERY = gql`
    query Member($username: String!) {
        member(username: $username) {
            id,
            email,
            username,
            firstName,
            lastName,
            certificates {
                url,
                description,
                expiryDate,
                certificateType {
                    id,
                    title
                }
            }
            image
        }
    }
`

// UserProfileScreen component
export default function UserProfileScreen({ route, navigation, editScreen = 'UserProfileEdit' }) {
    const { profileId, canEdit = false, editedData } = route.params;

    const [member, setMember] = React.useState(null);

    const App = useApp();
    const { linkCopiedToClipboard, onShare } = useShareCallback(createShareableLink(`users/${profileId}`));
    // const { linkCopiedToClipboard, onShare } = useShareCallback('users/');

    const { loading, error, data } = useQuery(MEMBER_QUERY, {
        variables: {
            username: profileId,
        },
        onCompleted: (data) => {
            setMember(data.member);
            navigation.setOptions({ documentTitle: `${data.member.firstName} ${data.member.lastName}` });
        }
    });

    // set header share button
    React.useEffect(() => {
        navigation.setOptions({
            headerRight: () => (
                <Pressable onPress={onShare} hitSlop={8} style={styles.headerShareButton}>
                    <ShareIcon style={styles.headerShareButtonIcon} />
                </Pressable>
            )
        });
    }, [])

    // update data on edit
    React.useEffect(() => {
        if (editedData) {
            setMember({ ...JSON.parse(JSON.stringify(member)), ...editedData });
        }
    }, [editedData])

    if (error) {
        console.log('Error loading user info: ', error.message);

        return (
            <Center>
                <StyledText>Could not load this user!</StyledText>
            </Center>
        )
    }

    if (loading || !member) {
        return (
            <Center>
                <StyledActivityIndicator />
            </Center>
        )
    }

    return (
        <StyledScrollView style={styles.main}>

            <Container>
                <Image source={{ uri: member.image }} resizeMode="cover" style={[styles.profileImage, {
                    width: App.width * 0.39,
                    height: App.width * 0.39,
                    borderRadius: App.width * 0.39 / 2,
                }]} />

                <StyledText fontWeight="bold" style={styles.name}>{member.firstName} {member.lastName}</StyledText>

                <Label style={styles.label}>Member Number</Label>
                <StyledText size="large" fontWeight="semibold" style={styles.info}>{member.username}</StyledText>

                {/* <Label style={styles.label}>Date of Birth:</Label>
                <StyledText size="large" fontWeight="semibold" style={styles.info}>{member.dob}</StyledText> */}
            </Container>

            <Separator fullWidth={true} />

            <View style={styles.certificatesLabelWrapper}>
                <StyledText size="large" fontWeight="semibold" style={styles.certificatesLabel}>Certificates</StyledText>
                <Label style={styles.certificatesExpiryLabel}>Expiry</Label>
            </View>
            {!member.certificates || member.certificates.length === 0 ? (
                <LinkListItem title="None" disabled />
            ) : (
                <View>
                    {member.certificates.map(item => (
                        <LinkListItem
                            key={item.certificateType.id + '_' + item.expiryDate}
                            title={item.certificateType.title}
                            subtitle={item.expiryDate ? item.expiryDate.slice(0, 10) : 'None          '}
                            href={item.url}
                            color="secondary"
                        />
                    ))}
                </View>
            )}

            <Container style={styles.buttonWrapper}>
                <View style={styles.shareButton}>
                    <StyledButton title="Share Profile" color="secondary" onPress={onShare} icon={ShareIcon} />
                    {linkCopiedToClipboard && <StyledText style={styles.copiedLinkText}>Link copied to clipboard.</StyledText>}
                </View>
                {canEdit &&
                    <StyledButton
                        title="Edit Profile"
                        icon={EditIcon}
                        onPress={() => navigation.navigate(editScreen, {
                            memberData: {
                                email: member.email,
                                firstName: member.firstName,
                                lastName: member.lastName,
                            }
                        })}
                        style={styles.button}
                    />
                }
            </Container>
        </StyledScrollView>
    )
}

const styles = StyleSheet.create({
    main: {
        flex: 1,
    },
    profileImage: {
        overflow: 'hidden',
        marginTop: 48,
        marginBottom: 24,
        backgroundColor: '#7e7e7e'
    },
    name: {
        fontSize: 35,
        lineHeight: 41,
        marginBottom: 24,
    },
    label: {
        marginBottom: 4,
    },
    info: {
        marginBottom: 20,
    },
    certificatesLabelWrapper: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'flex-end',
        paddingHorizontal: Theme.container.padding,
        marginTop: 20,
        marginBottom: 12,
    },
    certificatesLabel: {
    },
    certificatesExpiryLabel: {
        paddingRight: 50,
    },
    copiedLinkText: {
        marginTop: 4,
        marginLeft: 8,
    },
    buttonWrapper: {
        marginVertical: 32,
    },
    button: {
        marginBottom: 20,
    },
    headerShareButtonIcon: {
        color: Theme.colors.secondary,
    },
});
