import React from 'react';
import { StyleSheet, View } from 'react-native';
import { useMutation, gql } from '@apollo/client';

import Container from '../components/Container';
import StyledTextInput from '../components/StyledTextInput';
import StyledActivityIndicator from '../components/StyledActivityIndicator';
import Spacer from '../components/Spacer';
import StyledText from '../components/StyledText';
import H2 from '../components/H2';
import Label from '../components/Label';
import StyledLink from '../components/StyledLink';
import StyledButton from '../components/StyledButton';
import RightArrowIcon from '../icons/RightArrowIcon';
import StyledScrollView from '../components/StyledScrollView';

// mutation
const RESET_PASSWORD = gql`
    mutation ResetPassword($email: String!) {
        resetPassword(email: $email) {
            ok
        }
    }
`;

export default function ForgotPasswordScreen() {
    const [email, setEmail] = React.useState('');

    const [resetPassword, { loading, error, data }] = useMutation(RESET_PASSWORD, {
        variables: {
            email
        },
        onError: (error) => {
            console.log('Error sending reset link:', error.message);
        },
    })

    if (data) {
        return (
            <StyledScrollView style={styles.main}>
                <Container style={styles.container}>
                    <H2 style={styles.heading}>Success!</H2>
                    <StyledText style={styles.text}>Please check your email for further instructions.</StyledText>
                </Container>
            </StyledScrollView >
        )
    }

    return (
        <StyledScrollView style={styles.main}>
            <Container style={styles.container}>

                <H2 style={styles.heading}>Set Your password</H2>
                <StyledText style={styles.text}>If you have not logged before, Enter your email address below, and we'll email instructions for setting one.</StyledText>
                <StyledText style={styles.text}>*Note, if you do not have an email address associated with your UA179 account you will need to email <StyledLink href="mailto:mail@ualocal179.ca">mail@ualocal179.ca</StyledLink> to set one. Please provide your member # and the email address you would like associated with your profile</StyledText>

                <H2 style={styles.heading}>Reset Your password</H2>
                <StyledText style={styles.text}>If you have forgotten your password, enter your email address below, and we'll email instructions for setting a new one.</StyledText>

                <Label style={styles.label}>Email:</Label>
                <StyledTextInput
                    style={styles.textInput}
                    placeholder="example@mail.com"
                    value={email}
                    onChangeText={setEmail}
                    onSubmitEditing={resetPassword}
                    autoCapitalize="none"
                    returnKeyType="go"
                />
                <StyledButton color="secondary" icon={RightArrowIcon} onPress={resetPassword} style={styles.submitButton}>Submit</StyledButton>
                {loading && <StyledActivityIndicator size="small" style={styles.loadingIndicator} />}
                {error && <StyledText style={styles.error}>{error.message}</StyledText>}

            </Container>
        </StyledScrollView >
    )
}

const styles = StyleSheet.create({
    main: {
        flex: 1,
    },
    container: {
        flex: 1,
        paddingVertical: 8,
    },
    heading: {
        marginTop: 16,
    },
    text: {
        marginTop: 8,
    },
    label: {
        fontSize: 13,
        marginTop: 32,
        marginHorizontal: 12,
    },
    textInput: {
        width: '100%',
        marginVertical: 6,
    },
    submitButton: {
        marginTop: 16,
        marginBottom: 8,
    },
    loadingIndicator: {

    },
    error: {
        alignSelf: 'center',
        color: 'red',
    },
    success: {
        alignSelf: 'center',
    },
});
