import React from 'react'
import { Platform, Pressable as RNPressable } from 'react-native'

import Theme from '../App/Theme'

/**
 * Wrapper around react-native Pressable component.
 * 
 * Main purpose is to pass in hover, focus, and active events to styles,
 * but also provides a default 'pressable area' and android ripple.
 */

export default function Pressable({ children, style, android_ripple, onHoverIn, onHoverOut, onFocus, onBlur, ...props }) {
    const [hovered, setHovered] = React.useState(false);
    const [focused, setFocused] = React.useState(false);
    // const [active, setActive] = React.useState(false);

    // temp active state for web only
    const isActive = (pressed) => Platform.OS == 'web' && pressed;

    const pressableStyle = ({ pressed }) => [
        (typeof style === 'function') ? style({ pressed, hovered, active: isActive(pressed), focused }) : style,
    ]

    const pressableOnHoverIn = () => {
        setHovered(true);
        onHoverIn && onHoverIn();
    }

    const pressableOnHoverOut = () => {
        setHovered(false);
        onHoverOut && onHoverOut();
    }

    const pressableOnFocus = () => {
        setFocused(true);
        onFocus && onFocus();
    }

    const pressableOnBlur = () => {
        setFocused(false);
        onBlur && onBlur();
    }

    return (
        <RNPressable
            style={pressableStyle}
            onHoverIn={pressableOnHoverIn}
            onHoverOut={pressableOnHoverOut}
            onFocus={pressableOnFocus}
            onBlur={pressableOnBlur}
            pressRetentionOffset={{
                bottom: 7,
                left: 4,
                right: 4,
                top: 4
            }}
            android_ripple={android_ripple ? { color: Theme.links.pressColor, ...android_ripple } : null}
            {...props}
        >
            {children}
        </RNPressable>
    )
}
