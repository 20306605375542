import React from 'react'
import { StyleSheet } from 'react-native';

import StyledText from './StyledText'
import Container from './Container';

/**
 * Use to indicate an error when loading the screen.
 */

export default function Error({ message = "Could not connect to server!" }) {
    return (
        <Container>
            <StyledText style={styles.errorMessage}>{message}</StyledText>
        </Container>
    )
}

const styles = StyleSheet.create({
    errorMessage: {
        marginVertical: 24,
        textAlign: 'center'
    },
});
