import React from 'react'
import { StyleSheet, View } from 'react-native'

import Theme from '../App/Theme';

export default function Separator({ fullWidth = false, style, ...props }) {
    const separatorStyles = [
        styles.separator,
        fullWidth && styles.fullWidth,
        style,
    ]

    return (
        <View {...props} style={separatorStyles} />
    )
}

const styles = StyleSheet.create({
    separator: {
        height: StyleSheet.hairlineWidth,
        backgroundColor: Theme.colors.border,
        marginLeft: Theme.container.padding,
    },
    fullWidth: {
        marginLeft: 0,
    }
})
