import React from 'react'
import * as ReactNavigation from '@react-navigation/elements';
import { Platform, View, StyleSheet } from 'react-native';

import { useLinkStyles } from './StyledLink';
import LeftArrowIcon from '../icons/LeftArrowIcon';

/**
 * Wrapper around react-navigation/element/HeaderBackButton.
 * 
 * @param navigation the navigation object from the header
 * @param options the header options prop
 * @param back the header back prop
 */

export default function HeaderBackButton({ navigation, options, back }) {
    const ref = React.useRef(null);
    // hovered, focused, and active not currently supported
    const [hovered, setHovered] = React.useState(false);
    const [active, setActive] = React.useState(false);
    const [focused, setFocused] = React.useState(false);

    const linkStyles = useLinkStyles()({ hovered, active, focused });

    const onPress = () => {
        if (navigation.canGoBack()) {
            navigation.goBack()
        } else if (options.defaultBackScreen) {
            navigation.navigate(options.defaultBackScreen)
        }
    }

    if (!navigation.canGoBack() && !options.defaultBackScreen) {
        console.log('No default back screen found! If this screen should have a back button, please define one in your screen options.')
        return null;
    }

    return (
        <View ref={ref} style={linkStyles}>
            <ReactNavigation.HeaderBackButton
                onPress={onPress}
                label={options.headerBackTitle !== undefined
                    ? options.headerBackTitle
                    : back ? back.title : undefined}
                labelVisible={true}
                pressColor={options.headerPressColor}
                pressOpacity={options.headerPressOpacity}
                backImage={Platform.OS == 'web' ? () => <LeftArrowIcon color="white" style={styles.backIcon} /> : null}
            />
        </View>
    )
}

const styles = StyleSheet.create({
    backIcon: {
        margin: 8,
    }
})
