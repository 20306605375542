import React from 'react';

import UserProfileScreen from './UserProfileScreen';
import { useAuth } from '../App/AuthProvider';

export default function MyAccountScreen(props) {
    const { userId, token } = useAuth();
    props.route.params = { ...props.route.params, profileId: userId };

    if (!token)
        return null;

    return (
        <UserProfileScreen {...props} editScreen="MyAccountEdit" />
    )
}
