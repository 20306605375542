import React from 'react';
import Svg, { Path } from 'react-native-svg';

export default function LogoutIcon(props) {
    return (
        <Svg width="18" height="16" viewBox="0 0 18 16" fill="none" {...props}>
            <Path d="M6.33333 14.571H2.77778C2.30628 14.571 1.8541 14.4122 1.5207 14.1294C1.1873 13.8466 1 13.4631 1 13.0632V2.50789C1 2.10798 1.1873 1.72444 1.5207 1.44165C1.8541 1.15887 2.30628 1 2.77778 1H6.33333" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <Path d="M12.5557 11.5553L17.0001 7.78554L12.5557 4.01581" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <Path d="M16.9999 7.78549H6.33325" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </Svg>
    );
}
