import React from 'react'
import { StyleSheet } from 'react-native'
import Theme from '../App/Theme'
import StyledText from './StyledText'

export default function Label({ children, style, ...props }) {
    return (
        <StyledText size="small" style={[styles.label, style]} {...props}>{children}</StyledText>
    )
}

const styles = StyleSheet.create({
    label: {
        color: Theme.colors.textLight,
    }
})
