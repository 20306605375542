import React from 'react'
import { StyleSheet, View } from 'react-native'

import Theme from '../App/Theme';

export default function Container({ children, style, ...props }) {
    return (
        <View style={[styles.container, style]} {...props}>{children}</View>
    )
}

const styles = StyleSheet.create({
    container: {
        alignSelf: 'center',
        width: '100%',
        paddingHorizontal: Theme.container.padding,
    }
})
