import React from 'react'
import { View, StyleSheet } from 'react-native'
import { HeaderShownContext } from '@react-navigation/elements';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

export default function EmptyHeader({ options }) {
    const insets = useSafeAreaInsets();

    const isParentHeaderShown = React.useContext(HeaderShownContext);

    const statusBarHeight =
        options.headerStatusBarHeight !== undefined
            ? options.headerStatusBarHeight
            : isParentHeaderShown
                ? 0
                : insets.top;

    //console.log()

    return (
        <View style={styles.header(statusBarHeight)} />
    )
}


const styles = StyleSheet.create({
    header: height => ({
        height
    }),
})
