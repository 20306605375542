import React from 'react'
import { StyleSheet, View } from 'react-native'

export default function StyledList({ children, style, ...props }) {
    return (
        <View style={[styles.list, style]} {...props}>
            {children}
        </View>
    )
}

const styles = StyleSheet.create({
    list: {
        paddingLeft: 16,
    },
})
