
import React from 'react';
import { Platform, StyleSheet } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { NavigationContainer, createNavigationContainerRef, DarkTheme as defaultNavTheme, useLinkTo } from '@react-navigation/native';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { createStackNavigator } from '@react-navigation/stack';
import * as Linking from 'expo-linking';
import * as Notifications from 'expo-notifications';

import MyAccountScreen from '../screens/MyAccountScreen';
import MyAccountEditScreen from '../screens/MyAccountEditScreen';
import ScanScreen from '../screens/ScanScreen';
import SearchScreen from '../screens/SearchScreen';
import MoreScreen from '../screens/MoreScreen';
import UserProfileScreen from '../screens/UserProfileScreen';
import UserProfileEditScreen from '../screens/UserProfileEditScreen';
import CoursesScreen from '../screens/CoursesScreen';
import CourseDetailsScreen from '../screens/CourseDetailsScreen';
import NewsScreen from '../screens/NewsScreen';
import JobPostingsScreen from '../screens/JobPostingsScreen';
import JobPostingDetailsScreen from '../screens/JobPostingDetailsScreen';
import ExternalLinksScreen from '../screens/ExternalLinksScreen';
import ContactScreen from '../screens/ContactScreen';
import LogInScreen from '../screens/LogInScreen';
import ForgotPasswordScreen from '../screens/ForgotPasswordScreen';
import NewsPostScreen from '../screens/NewsPostScreen';
import SetNewPasswordScreen from '../screens/SetNewPasswordScreen';
import NotFoundScreen from '../screens/NotFoundScreen';
import VersionScreen from '../screens/VersionScreen';

import HeaderWithLargeTitle from '../components/HeaderWithLargeTitle';
import HeaderWithLogo from '../components/HeaderWithLogo';
import EmptyHeader from '../components/EmptyHeader';
import DefaultHeader from '../components/DefaultHeader';

import ProfileIcon from '../icons/ProfileIcon';
import ScanIcon from '../icons/ScanIcon';
import SearchIcon from '../icons/SearchIcon';
import BarsIcons from '../icons/BarsIcon';
import EllipsesIcon from '../icons/EllipsesIcon';

import { useAuth } from './AuthProvider';
import Theme from './Theme';
import Config from './Config';
import DebugScreen from '../screens/DebugScreen';
import { isDebug, isDev } from '../util/debug';
import PrivacyPolicyScreen from '../screens/PrivacyPolicyScreen';
import { getPendingNotifications, getUrlFromNotificationResponse } from './PushNotifications';
import { isWindowWidthGreaterThanAppWidth } from '../util';

/**
 * Helper function to get screen header so that we dont need to define it multiple times.
 */
function getScreenHeader(screen) {
    switch (screen) {
        case 'More':
        case 'MyAccount':
        case 'Scan':
        case 'Search':
            return HeaderWithLogo;
        case 'MoreMyAccount':
        case 'Courses':
        case 'News':
        case 'JobPostings':
        case 'ExternalLinks':
        case 'Contact':
            return HeaderWithLargeTitle;
        case 'LogIn':
            return EmptyHeader;
        default:
            return DefaultHeader;
    }
}

/**
 * Default screen options (for use in Stack.Navigator)
 */
function defaultScreenOptions({ route }) {
    return {
        header: getScreenHeader(route.name),
        headerMode: 'screen',
        headerPressColor: Theme.links.pressColor,
        headerPressOpacity: Theme.links.pressOpacity,
    }
}

// account stack
const MyAccountStack = createStackNavigator()
function MyAccountStackScreen() {
    const { token, userId } = useAuth();

    return (
        <MyAccountStack.Navigator
            screenOptions={defaultScreenOptions}
            initialRouteName="MyAccount"
        >
            {token && userId ?
                <>
                    <MyAccountStack.Screen
                        name="MyAccount"
                        component={MyAccountScreen}
                        options={{ title: "My Account" }}
                    />
                    <MyAccountStack.Screen
                        name="MyAccountEdit"
                        component={MyAccountEditScreen}
                        options={{ title: "Edit My Account" }}
                    >
                    </MyAccountStack.Screen>
                </>
                :
                <>
                    <MyAccountStack.Screen
                        name="LogIn"
                        component={LogInScreen}
                        options={{ title: "Log In", animationTypeForReplace: 'pop', }}
                    />
                    <MyAccountStack.Screen
                        name="ForgotPassword"
                        component={ForgotPasswordScreen}
                        options={{ title: "Forgot Password", defaultBackScreen: 'LogIn' }}
                    />
                    <MyAccountStack.Screen
                        name="SetNewPassword"
                        component={SetNewPasswordScreen}
                        options={{ title: "Set New Password" }}
                    />
                </>
            }
            <MyAccountStack.Screen name="Contact" component={ContactScreen} options={{ title: 'Contact UA 179', defaultBackScreen: 'LogIn' }} />
            <MyAccountStack.Screen name="PrivacyPolicy" component={PrivacyPolicyScreen} options={{ title: 'Privacy Policy', defaultBackScreen: 'LogIn' }} />
            <MyAccountStack.Screen name="Version" component={VersionScreen} options={{ title: 'App Version', defaultBackScreen: 'LogIn' }} />
        </MyAccountStack.Navigator>
    )
}

// 'More' stack
const MoreStack = createStackNavigator()
function MoreStackScreen() {
    return (
        <MoreStack.Navigator
            screenOptions={defaultScreenOptions}
            initialRouteName="More"
        >
            <MoreStack.Screen name="More" component={MoreScreen} />
            {/* <MoreStack.Screen name="MoreMyAccount" component={MyAccountScreen} options={{ title: 'My Account', defaultBackScreen: 'More' }} /> */}
            <MoreStack.Screen name="Courses" component={CoursesScreen} options={{ defaultBackScreen: 'More' }} />
            <MoreStack.Screen name="CourseDetails" component={CourseDetailsScreen} options={{ title: '', defaultBackScreen: 'Courses' }} />
            <MoreStack.Screen name="News" component={NewsScreen} options={{ defaultBackScreen: 'More' }} />
            <MoreStack.Screen name="NewsPost" component={NewsPostScreen} options={{ title: '', defaultBackScreen: 'News' }} />
            <MoreStack.Screen name="JobPostings" component={JobPostingsScreen} options={{ title: 'Job Postings', defaultBackScreen: 'More' }} />
            <MoreStack.Screen name="JobPostingDetails" component={JobPostingDetailsScreen} options={{ title: 'Job Details', headerBackTitle: 'Back', defaultBackScreen: 'JobPostings' }} />
            <MoreStack.Screen name="ExternalLinks" component={ExternalLinksScreen} options={{ title: 'External Links', defaultBackScreen: 'More' }} />
            <MoreStack.Screen name="Contact" component={ContactScreen} options={{ title: 'Contact UA 179', defaultBackScreen: 'More' }} />
            <MoreStack.Screen name="PrivacyPolicy" component={PrivacyPolicyScreen} options={{ title: 'Privacy Policy', defaultBackScreen: 'More' }} />
            {(isDebug() || isDev()) && <MoreStack.Screen name="Debug" component={DebugScreen} options={{ title: 'Debug Log', defaultBackScreen: 'More' }} />}
        </MoreStack.Navigator>
    );
}

// 'Scan' stack 
// ('Scan' and 'Search' need their own stacks because of a warning caused by the custom header when 
// at the 'Tab' level)
// const ScanStack = createStackNavigator()
// function ScanStackScreen() {
//     return (
//         <ScanStack.Navigator
//             screenOptions={defaultScreenOptions}
//         >
//             <ScanStack.Screen name="Scan" component={ScanScreen} options={{ headerTitleIcon: ScanIcon }} />
//             <ScanStack.Screen name="UserProfile" component={UserProfileScreen} options={{ title: "Profile" }} />
//             <ScanStack.Screen name="UserProfileEdit" component={UserProfileEditScreen} options={{ title: "Edit Profile" }} />
//         </ScanStack.Navigator>
//     )
// }

// 'Search' stack
const SearchStack = createStackNavigator()
function SearchStackScreen() {
    return (
        <SearchStack.Navigator
            screenOptions={defaultScreenOptions}
            initialRouteName="Search"
        >
            <SearchStack.Screen name="Search" component={SearchScreen} options={{ title: 'Member Search' }} />
            <SearchStack.Screen name="UserProfile" component={UserProfileScreen} options={{ title: "Profile", headerBackTitle: "Search", defaultBackScreen: 'Search' }} />
        </SearchStack.Navigator>
    )
}

// to setup universal links, see https://docs.expo.dev/guides/linking/?redirected#universal-links-on-ios 
const rootPrefixes = [
    Linking.createURL('/'),
    Config.webUrl,
];
const rootConfig = {
    initialRouteName: 'MyAccountStack',
    screens: {
        MyAccountStack: {
            initialRouteName: 'MyAccount',
            screens: {
                MyAccount: 'my-account',
                MyAccountEdit: 'my-account-edit',
                LogIn: 'log-in',
                ForgotPassword: 'forgot-password',
                SetNewPassword: 'reset/:uidb64/:token',
                Contact: 'contact/:officeLocation',
                PrivacyPolicy: 'privacy-policy',
                Version: 'version',
            },
        },
        SearchStack: {
            initialRouteName: 'Search',
            screens: {
                Search: 'search',
                UserProfile: {
                    path: 'users/:profileId'
                },
            },
        },
        MoreStack: {
            initialRouteName: 'More',
            screens: {
                More: 'more',
                Courses: 'courses',
                CourseDetails: 'courses/:courseId',
                News: 'news',
                NewsPost: 'news/:postId',
                JobPostings: 'job-postings',
                JobPostingDetails: 'job-postings/:jobId',
                ExternalLinks: 'external-links',
                Contact: 'more/contact/:officeLocation',
                PrivacyPolicy: 'more/privacy-policy',
            },
        },
        NotFound: '*',
    }
}

// document title for web
const documentTitleFormatter = (options, route) => {
    if (route.name == 'CourseDetails')
        return options?.documentTitle ?? 'Courses';

    if (route.name == 'NewsPost')
        return options?.documentTitle ?? 'News';

    if (route.name == 'JobPostingDetails')
        return options?.documentTitle ?? 'Job Postings';

    if (route.name == 'MyAccount')
        return 'My Account';

    // fix bug when you click 'More' tab
    if (route.name == 'MoreStack')
        return options?.documentTitle ?? 'More';

    return options?.documentTitle ?? options?.title ?? route?.name ?? 'UA 179';
}

// a reference to the navigation container for use outside the Navigation component
export const navigationRef = createNavigationContainerRef()

// tab icon helper (removes unnecessary props)
const TabBarIcon = (Icon) => ({ focused, ...props }) => <Icon {...props} />

// main tabs
const RootTabs = createBottomTabNavigator();
export default function Navigation() {
    const linking = {
        prefixes: rootPrefixes,
        config: rootConfig,
        async getInitialURL() {
            const url = await Linking.getInitialURL();
            console.log(`Linking.getInitialURL: ${url}`)

            if (url != null) {
                return url;
            }

            const response = await Notifications.getLastNotificationResponseAsync();
            console.log(`getLastNotificationResponseAsync: ${!!response}`)

            if (response) {
                const urlFromNotification = getUrlFromNotificationResponse(response);
                console.log(`Initial url received from getLastNotificationResponseAsync: ${urlFromNotification}`);
                return urlFromNotification;
            }

            // const pendingNotifications = getPendingNotifications();
            // console.log(`getPendingNotifications (length): ${pendingNotifications.length}`)

            // if (pendingNotifications.length > 0) {
            //     const urlFromNotification = pendingNotifications[pendingNotifications.length - 1];
            //     console.log(`Initial url received from getPendingNotifications: ${urlFromNotification}`);
            //     return urlFromNotification;
            // }

            return null;
        },
        subscribe(listener) {
            const onReceiveURL = ({ url }) => listener(url);

            // Listen to incoming links from deep linking
            const deepLinkSubscription = Linking.addEventListener("url", onReceiveURL);

            // Listen to expo push notifications
            const notificationSubscription =
                Notifications.addNotificationResponseReceivedListener((response) => {
                    const url = getUrlFromNotificationResponse(response);

                    if (url) {
                        listener(url);
                    }
                });

            return () => {
                // Clean up the event listeners
                deepLinkSubscription.remove();
                notificationSubscription.remove();
            };
        },
    };

    const { token } = useAuth();
    const insets = useSafeAreaInsets();

    return (
        <NavigationContainer ref={navigationRef} theme={navTheme} linking={linking} documentTitle={{ formatter: documentTitleFormatter }}>
            <RootTabs.Navigator
                initialRouteName="MyAccountStack"
                screenOptions={{
                    tabBarShowLabel: false,
                    tabBarStyle: { height: insets.bottom + 52 },
                    tabBarActiveTintColor: Theme.colors.primary,
                    headerShown: false,
                    unmountOnBlur: Platform.OS == 'web' ? true : false,
                }}
                screenListeners={({ navigation }) => ({
                    blur: () => Platform.OS == 'web' && navigation.setParams({ screen: undefined })
                })}
            >
                <RootTabs.Screen name="MyAccountStack" component={MyAccountStackScreen} options={{ tabBarIcon: TabBarIcon(ProfileIcon), tabBarAccessibilityLabel: 'My account' }} />
                {/* <RootTabs.Screen name="ScanStack" component={ScanStackScreen} options={{ tabBarIcon: ScanIcon }} /> */}
                <RootTabs.Screen name="SearchStack" component={SearchStackScreen} options={{ tabBarIcon: TabBarIcon(SearchIcon), tabBarAccessibilityLabel: 'Search member' }} />
                {token && (
                    <RootTabs.Screen name="MoreStack" component={MoreStackScreen} options={{ tabBarIcon: TabBarIcon(EllipsesIcon), tabBarAccessibilityLabel: 'More' }} />
                )}
                <RootTabs.Screen name="NotFound" component={NotFoundScreen} options={{ tabBarButton: () => null, title: 'Not Found', header: (props) => <HeaderWithLogo {...props} />, headerShown: true, headerMode: 'screen', defaultBackScreen: 'MyAccount' }} />
            </RootTabs.Navigator>
        </NavigationContainer >
    );
}

export function navigate(screen, params = null, initialUrl = null) {
    if (navigationRef.isReady()) {
        navigationRef.current.navigate(screen, params);
    }
}

// theme 
const navTheme = {
    ...defaultNavTheme,
    dark: true,
    colors: {
        ...defaultNavTheme.colors,
        primary: Theme.colors.secondary,
        background: Theme.colors.background,
        card: Theme.colors.card,
        text: Theme.colors.text,
        border: Theme.colors.border,
        notification: Theme.colors.primary,
    }
}
