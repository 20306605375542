import React from 'react';
import { RefreshControl, StyleSheet, View } from 'react-native';
import { gql, useQuery, NetworkStatus } from '@apollo/client';
import { format } from "date-fns";

import StyledFlatList from '../components/StyledFlatList';
import Separator from '../components/Separator';
import JobSearchIcon from '../icons/JobSearchIcon';
import NoResults from '../components/NoResults';
import Loading from '../components/Loading';
import Error from '../components/Error';
import PostItem from '../components/PostItem';

const JOB_POSTINGS_QUERY = gql`
    query JobPostsList {
        jobPosts {
            id
            title
            description
            createdAt
        }
    }
`;

export default function JobPostingsScreen() {
    const { loading, error, data, fetchMore, refetch, networkStatus } = useQuery(JOB_POSTINGS_QUERY, {
        notifyOnNetworkStatusChange: true,
    });

    if (loading && networkStatus !== NetworkStatus.refetch) {
        return (
            <Loading />
        )
    }

    if (error) {
        console.log('Error loading job posts: ', error.message);

        return (
            <Error />
        )
    }

    return (
        <View style={styles.container}>
            <StyledFlatList
                data={data?.jobPosts}
                keyExtractor={(item) => item.title}
                renderItem={({ item }) => (
                    <PostItem
                        title={item.title}
                        dateText={`Posted ${format(new Date(item.createdAt), 'MMM d, yyyy')}`}
                        excerpt={item.description}
                        linkTo={{ screen: 'JobPostingDetails', params: { jobId: item.id } }}
                    />
                )}
                ItemSeparatorComponent={() => <Separator style={styles.separator} />}
                refreshControl={
                    <RefreshControl
                        refreshing={loading}
                        onRefresh={() => { console.log('refetching data'); refetch() }}
                        title=""
                        tintColor="#999999"
                    />
                }
                ListHeaderComponent={() => {
                    if (!loading && data?.jobPosts.length === 0) {
                        return (
                            <NoResults
                                title="No job postings at the moment."
                                subtitle="Please check again later."
                                Icon={JobSearchIcon}
                            />
                        );
                    }
                    return null
                }}
                ListFooterComponent={() => loading && data?.jobPosts.length > 0 && networkStatus !== NetworkStatus.refetch && <Loading />}
            />
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
    },
    separator: {
    },
});
