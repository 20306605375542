import React from 'react';
import Svg, { Path } from 'react-native-svg';

export default function SearchLargeIcon({ size = 125, color = "currentColor", ...props }) {
    return (
        <Svg width={size + 1} height={size} viewBox="0 0 126 125" fill="none" {...props} >
            <Path d="M91.7509 80.8064C99.3164 70.4825 102.705 57.6828 101.239 44.9679C99.7722 32.253 93.5589 20.5606 83.8419 12.23C74.1249 3.89937 61.6208 -0.455118 48.8311 0.037692C36.0414 0.530502 23.9094 5.83427 14.8622 14.8879C5.81505 23.9415 0.519963 36.0773 0.0363048 48.8674C-0.447353 61.6574 3.91608 74.1585 12.2537 83.8695C20.5912 93.5806 32.288 99.7854 45.004 101.243C57.72 102.7 70.5173 99.3023 80.8357 91.7294H80.8279C81.0623 92.0419 81.3123 92.3388 81.5936 92.6279L111.675 122.709C113.14 124.175 115.127 124.999 117.2 125C119.273 125.001 121.261 124.178 122.727 122.713C124.193 121.248 125.017 119.261 125.018 117.188C125.018 115.115 124.196 113.127 122.731 111.661L92.6494 81.5799C92.37 81.2971 92.0697 81.0359 91.7509 80.7986V80.8064ZM93.7667 50.772C93.7667 56.4153 92.6552 62.0034 90.4956 67.2171C88.3359 72.4309 85.1706 77.1682 81.1801 81.1586C77.1897 85.1491 72.4524 88.3145 67.2386 90.4741C62.0249 92.6337 56.4368 93.7452 50.7935 93.7452C45.1502 93.7452 39.5621 92.6337 34.3484 90.4741C29.1346 88.3145 24.3973 85.1491 20.4069 81.1586C16.4164 77.1682 13.2511 72.4309 11.0915 67.2171C8.93186 62.0034 7.82032 56.4153 7.82032 50.772C7.82032 39.3748 12.3478 28.4444 20.4069 20.3854C28.4659 12.3264 39.3963 7.79883 50.7935 7.79883C62.1907 7.79883 73.1211 12.3264 81.1801 20.3854C89.2392 28.4444 93.7667 39.3748 93.7667 50.772V50.772Z" fill={color} />
        </Svg>
    );
}
