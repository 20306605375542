import React from 'react'
import { View, StyleSheet, Platform } from 'react-native'
import { getHeaderTitle, HeaderShownContext } from '@react-navigation/elements';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import HeaderBackButton from './HeaderBackButton';
import StyledText from './StyledText';
import Theme from '../App/Theme'

export default function DefaultHeader({ navigation, route, options, back }) {
    const insets = useSafeAreaInsets();

    const isParentHeaderShown = React.useContext(HeaderShownContext);

    const statusBarHeight =
        options.headerStatusBarHeight !== undefined
            ? options.headerStatusBarHeight
            : isParentHeaderShown
                ? 0
                : insets.top;

    const headerStyle = [
        styles.header,
        {
            paddingTop: statusBarHeight,
            height: (Platform.OS == 'ios' ? 60 : 54) + statusBarHeight,
        }
    ];

    return (
        <View style={headerStyle}>
            <View style={styles.headerLeft} >
                {options.headerLeft ? <options.headerLeft /> :
                    <HeaderBackButton navigation={navigation} options={options} back={back} />
                }
            </View>
            <StyledText style={styles.title} fontWeight='semibold'>{getHeaderTitle(options, route.name)}</StyledText>
            <View style={styles.headerRight}>
                {options.headerRight && <options.headerRight />}
            </View>
        </View>
    )
}

const styles = StyleSheet.create({
    header: {
        flexDirection: 'row',
        alignItems: 'center',
        backgroundColor: Theme.colors.card,
        borderBottomWidth: StyleSheet.hairlineWidth,
        borderBottomColor: Theme.colors.border,
    },
    headerLeft: {
        flex: 1,
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
    },
    headerRight: {
        flex: 1,
        alignItems: 'flex-end',
        justifyContent: 'center',
        paddingRight: Theme.container.padding,
    },
    title: {
        fontSize: 17,
        color: 'white',
        lineHeight: 24,
        textAlign: 'center',
    },
})
