import { Link, useLinkProps } from '@react-navigation/native'
import React from 'react'
import { Linking, Platform, StyleSheet, Text, TouchableOpacity } from 'react-native';
import * as WebBrowser from 'expo-web-browser';

import Theme from '../App/Theme';
import PressableText from './PressableText';

/**
 * Wrapper for react-navigation Link.
 */

export default function StyledLink({ to, action, href, openInBrowser = false, children, style, textStyle, onResponderGrant, onResponderRelease, ...props }) {
    let onPress = null, linkProps = null;

    if (href) {
        onPress = async () => {
            console.log('clicked link:', href)
            Linking.openURL(href);
            // try {
            //     if (openInBrowser && Platform.OS !== 'web')
            //         await WebBrowser.openBrowserAsync(href);
            //     else
            //         await Linking.openURL(href);
            // } catch (e) {
            //     console.log('Failed to open URL:', e);
            // }
        };
    } else if (to) {
        ({ onPress, ...linkProps } = useLinkProps({ to, action }));
    }

    const linkStyles = useLinkStyles([
        styles.linkText,
        style,
        textStyle,
    ])

    return (
        <PressableText onPress={onPress} style={linkStyles} {...props} {...linkProps}>
            {children}
        </PressableText>
    )
}

const styles = StyleSheet.create({
    link: {
        ...Platform.select({
            web: {
                cursor: 'pointer',
                outlineStyle: 'none',
                transitionDuration: Theme.links.transitionDuration,
            }
        })
    },
    linkPressed: {
        opacity: Theme.links.pressOpacity,
    },
    linkHovered: {
        opacity: Theme.links.hoverOpacity,
    },
    linkActive: {
        opacity: Theme.links.activeOpacity,
    },
    linkText: {
        color: Theme.colors.link,
    }
})

export const useLinkStyles = (style) => ({ pressed, hovered, active, focused }) => [
    styles.link,
    pressed && styles.linkPressed,
    hovered && styles.linkHovered,
    active && styles.linkActive,
    typeof style === 'function' ? style({ pressed, hovered, active, focused }) : style,
].flat(1)
