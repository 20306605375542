import React from 'react'
import { View, StyleSheet, Platform } from 'react-native'
import * as Linking from 'expo-linking';
import { gql, useQuery } from '@apollo/client';
import { format } from "date-fns";

import StyledScrollView from '../components/StyledScrollView';
import Center from '../components/Center';
import H2 from '../components/H2';
import StyledText from '../components/StyledText';
import StyledLink from '../components/StyledLink';
import Label from '../components/Label';
import Separator from '../components/Separator';
import Container from '../components/Container';
import StyledActivityIndicator from '../components/StyledActivityIndicator';
import ShareButton from '../components/ShareButton';
import { createShareableLink } from '../util';

const JOB_POST_QUERY = gql`
  query JobPost($jobId: Int) {
    jobPost(id: $jobId) {
      title
      description
      startDate
      contractor {
        title
      }
      location
      hours {
        title
      }
      safetyRequirements {
        title
      }
      createdAt
      tradeAndExperiences {
        id
        jobPost {
          title
        }
        quantity
        trade {
          title
        }
        experienceLevel {
          title
        }
      }
      links {
        id
        title
        link
      }
    }
  }
`;

export default function JobPostingDetailsScreen({ route, navigation }) {
    const { jobId } = route.params;
    const [sortedTrades, setSortedTrades] = React.useState([]);

    const { loading, error, data } = useQuery(JOB_POST_QUERY, {
        variables: {
            jobId
        },
        onCompleted: (data) => {
            if (Platform.OS == 'web')
                navigation.setOptions({ documentTitle: data.jobPost.title });
        },
    })

    React.useEffect(() => {
        if (data) {
            setSortedTrades([...data.jobPost.tradeAndExperiences].sort((a, b) => a.trade.title > b.trade.title ? 1 : -1));
        }
    }, [data])

    if (loading) {
        return (
            <Center>
                <StyledActivityIndicator />
            </Center>
        )
    }

    if (error) {
        console.log('Error loading job: ', error.message);

        return (
            <Center>
                <StyledText>Could not find job.</StyledText>
            </Center>
        )
    }

    return (
        <StyledScrollView style={styles.main}>

            <Container style={styles.container}>
                <Label style={styles.label}>Job Title</Label>
                <H2 style={styles.jobTitle}>{data.jobPost.title}</H2>

                <Label style={styles.label}>Start Date:</Label>
                <StyledText fontWeight="semibold" style={styles.info}>{format(new Date(data.jobPost.startDate + "T00:00"), 'MMMM dd, yyyy')}</StyledText>

                <Label style={styles.label}>Location:</Label>
                <StyledText fontWeight="semibold" style={styles.info}>{data.jobPost.location}</StyledText>

                <Label style={styles.label}>Hours:</Label>
                <StyledText fontWeight="semibold" style={styles.info}>{data.jobPost.hours.title}</StyledText>

                <Label style={styles.label}>Safety Requirements:</Label>
                <StyledText fontWeight="semibold" style={styles.info}>{data.jobPost.safetyRequirements.map(item => item.title).join(', ')}</StyledText>

                {sortedTrades.length > 0 && (
                    <>
                        <Label style={styles.label}>Trades And Experiences:</Label>
                        {sortedTrades.map(tradeAndExperience => (
                            <StyledText key={tradeAndExperience.id} fontWeight="semibold" style={[styles.info, styles.infoListItem]}>{tradeAndExperience.trade.title} <StyledText style={styles.subInfo}>- {tradeAndExperience.experienceLevel.title} x {tradeAndExperience.quantity}</StyledText></StyledText>
                        ))}
                    </>
                )}
            </Container>

            <Separator style={styles.separator} />

            <Container style={styles.container}>
                <Label style={styles.label}>Details:</Label>
                <StyledText style={styles.jobDetails}>{data.jobPost.description}</StyledText>
            </Container>

            {data.jobPost.links.length > 0 && (
                <>
                    <Separator style={styles.separator} />

                    <Container style={styles.container}>
                        <Label style={styles.label}>More Info</Label>
                        {data.jobPost.links.map(link => (
                            <StyledLink fontWeight="semibold" style={styles.link} key={link.id} href={link.link}>{link.title}</StyledLink>
                        ))}
                    </Container>
                </>
            )}

            <Separator style={styles.separator} />

            <Container style={styles.container}>
                {/* <StyledButton color="secondary" onPress={() => console.log('applying for job...')} icon={ApplyIcon} style={styles.button}>Apply Now</StyledButton> */}
                <ShareButton content={createShareableLink(`job-postings/${jobId}`)} style={styles.button}>Share Job Posting</ShareButton>
            </Container>

        </StyledScrollView>
    )
}

const styles = StyleSheet.create({
    main: {
        flex: 1,
        marginTop: 16,
    },
    label: {
        marginBottom: 2,
    },
    jobTitle: {
        marginBottom: 12,
    },
    info: {
        fontSize: 18,
        lineHeight: 24,
        marginBottom: 8,
    },
    infoListItem: {
        marginBottom: 2,
    },
    subInfo: {
        fontSize: 14,
        lineHeight: 20,
        marginBottom: 4,
    },
    jobDetails: {
        marginBottom: 4,
    },
    link: {
        marginBottom: 2,
    },
    separator: {
        marginTop: 16,
        marginBottom: 16,
    },
    button: {
        marginBottom: 24,
    },
    noMarginBottom: {
        marginBottom: 0,
    }
});