import React from 'react'
import { StyleSheet } from 'react-native';
import Theme from '../App/Theme';

import Container from './Container'
import StyledText from './StyledText'

/**
 * Use when there are no results to show.
 */

export default function NoResults({ title, subtitle, Icon }) {
    return (
        <Container style={styles.container}>
            {Icon && <Icon color={Theme.colors.largeIcon} style={styles.icon} />}
            {title && <StyledText fontWeight="semibold" style={styles.title}>{title}</StyledText>}
            {subtitle && <StyledText fontWeight="semibold" style={styles.subtitle}>{subtitle}</StyledText>}
        </Container>
    )
}

const styles = StyleSheet.create({
    container: {
        alignItems: 'center',
        paddingVertical: 24,
    },
    icon: {
        marginTop: 8,
        marginBottom: 28,
    },
    title: {
        textAlign: 'center',
        marginBottom: 12,
    },
    subtitle: {
        textAlign: 'center',
        marginBottom: 12,
    },
});
