import React from 'react'
import { Text, StyleSheet } from 'react-native'

/**
 * Text element with common styles and custom font. This should always 
 * be used instead of the default Text element.
 */

function StyledText({ children, size, fontWeight, style, ...props }, ref) {

    const textStyles = [
        styles.text,
        fontWeight && fontWeight === 'semibold' && styles.textSemiBold,
        fontWeight && fontWeight === 'bold' && styles.textBold,
        size && size === 'small' && styles.textSmall,
        size && size === 'large' && styles.textLarge,
        style,
    ]

    return (
        <Text ref={ref} style={textStyles} {...props}>{children}</Text>
    )
}

export default React.forwardRef(StyledText);

const styles = StyleSheet.create({
    text: {
        fontFamily: 'OpenSans_400Regular',
        fontWeight: 'normal',
        color: 'white',
        fontSize: 14,
        lineHeight: 21,
    },
    textSemiBold: {
        fontFamily: 'OpenSans_600SemiBold',
        fontWeight: '600',
    },
    textBold: {
        fontFamily: 'OpenSans_700Bold',
        fontWeight: 'bold',
    },
    textSmall: {
        fontSize: 12,
        lineHeight: 18,
    },
    textLarge: {
        fontSize: 20,
        lineHeight: 24,
    }
})
