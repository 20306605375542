import React from 'react';
import { RefreshControl, StyleSheet, View } from 'react-native';
import { useQuery, gql, NetworkStatus } from '@apollo/client';
import { format } from "date-fns";

import StyledFlatList from '../components/StyledFlatList';
import Separator from '../components/Separator';
import Loading from '../components/Loading';
import Error from '../components/Error';
import PostItem from '../components/PostItem';
import NoResults from '../components/NoResults';

// query
const POSTS_QUERY = gql`
    query Posts {
        posts {
            id
            title
            content
            createdAt
        }
    }
`;

export default function NewsScreen() {
    const { loading, error, data, refetch, networkStatus } = useQuery(POSTS_QUERY);

    if (loading && networkStatus !== NetworkStatus.refetch) {
        return (
            <Loading />
        )
    }

    if (error) {
        console.log('Error loading job posts: ', error.message);

        return (
            <Error />
        )
    }

    return (
        <View style={styles.container}>
            <StyledFlatList
                data={data.posts}
                keyExtractor={(item) => String(item.id)}
                renderItem={({ item }) => (
                    <PostItem
                        title={item.title}
                        dateText={format(new Date(item.createdAt), 'MMMM d, yyyy')}
                        excerpt={item.content}
                        linkTo={{ screen: 'NewsPost', params: { postId: item.id } }}
                    />
                )}
                ItemSeparatorComponent={() => <Separator style={styles.separator} />}
                refreshControl={
                    <RefreshControl
                        refreshing={loading}
                        onRefresh={() => { console.log('refetching data'); refetch() }}
                        title=""
                        tintColor="#999999"
                    />
                }
                ListHeaderComponent={() => {
                    if (!loading && data?.posts.length === 0) {
                        return (
                            <NoResults
                                title="No news posts have been created yet."
                                subtitle="Please check again later."
                            />
                        );
                    }
                    return null
                }}
                ListFooterComponent={() => loading && data?.posts.length > 0 && networkStatus !== NetworkStatus.refetch && <Loading />}
            />
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
    },
    separator: {
    },
});
