import React from 'react';
import Svg, { Path } from 'react-native-svg';

export default function LinksIcon({ size = 107, color = "currentColor", ...props }) {
    return (
        <Svg width={size} height={size} viewBox="0 0 107 107" fill="none" {...props} >
            <Path d="M96.3 0H10.7C7.86218 0 5.1406 1.12732 3.13396 3.13396C1.12732 5.1406 0 7.86218 0 10.7V74.9C0 77.7378 1.12732 80.4594 3.13396 82.466C5.1406 84.4727 7.86218 85.6 10.7 85.6H42.8V96.3H26.75V107H80.25V96.3H64.2V85.6H96.3C99.1378 85.6 101.859 84.4727 103.866 82.466C105.873 80.4594 107 77.7378 107 74.9V10.7C107 7.86218 105.873 5.1406 103.866 3.13396C101.859 1.12732 99.1378 0 96.3 0ZM96.3 74.9H10.7V10.7H96.3V74.9ZM78.9125 58.85C78.9125 61.3331 77.9261 63.7145 76.1703 65.4703C74.4145 67.2261 72.0331 68.2125 69.55 68.2125C67.0669 68.2125 64.6855 67.2261 62.9297 65.4703C61.1739 63.7145 60.1875 61.3331 60.1875 58.85L42.8 50.076C41.446 51.1183 39.8271 51.7607 38.1268 51.9301C36.4265 52.0996 34.7127 51.7895 33.1795 51.035C31.6464 50.2804 30.3552 49.1115 29.4523 47.6608C28.5494 46.2101 28.0709 44.5355 28.0709 42.8268C28.0709 41.118 28.5494 39.4434 29.4523 37.9927C30.3552 36.542 31.6464 35.3731 33.1795 34.6185C34.7127 33.864 36.4265 33.5539 38.1268 33.7234C39.8271 33.8928 41.446 34.5351 42.8 35.5775L60.1875 26.75C60.1575 24.6033 60.8579 22.5102 62.1738 20.8138C63.4897 19.1175 65.3429 17.9186 67.4296 17.4138C69.5163 16.9091 71.7126 17.1284 73.6584 18.0357C75.6041 18.9431 77.1837 20.4847 78.1382 22.4078C79.0927 24.3308 79.3654 26.5211 78.9116 28.6195C78.4578 30.7179 77.3044 32.5998 75.6406 33.9566C73.9768 35.3134 71.9014 36.0645 69.7546 36.0869C67.6078 36.1092 65.5172 35.4014 63.8255 34.0795L46.5985 42.8L63.8255 51.4135C65.2063 50.3201 66.869 49.6408 68.6205 49.4546C70.372 49.2684 72.1404 49.583 73.7202 50.3617C75.3 51.1404 76.6266 52.3514 77.5457 53.8539C78.4647 55.3564 78.9387 57.0889 78.9125 58.85Z" fill={color} />
        </Svg>
    );
}
