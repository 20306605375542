import React from 'react';
import { View, StyleSheet, Platform } from 'react-native';
import { useQuery, gql } from '@apollo/client';
import { format } from "date-fns";
import * as Linking from 'expo-linking';

import StyledScrollView from '../components/StyledScrollView';
import Center from '../components/Center';
import Label from '../components/Label';
import H2 from '../components/H2';
import StyledText from '../components/StyledText';
import StyledLink from '../components/StyledLink';
import StyledActivityIndicator from '../components/StyledActivityIndicator';
import Separator from '../components/Separator';
import Container from '../components/Container';
import Theme from '../App/Theme';
import ShareButton from '../components/ShareButton';
import { createShareableLink } from '../util';

// query
const POST_QUERY = gql`
    query Post($postId: Int) {
        post(id: $postId) {
            id
            title
            content
            createdAt
            links {
                id
                title
                link
            }
        }
    }
`;

export default function NewsPostScreen({ route, navigation }) {
    const { postId } = route.params;

    const { loading, error, data } = useQuery(POST_QUERY, {
        variables: {
            postId
        },
        onCompleted: (data) => {
            if (Platform.OS == 'web')
                navigation.setOptions({ documentTitle: data.post.title });
        },
    })

    if (loading) {
        return (
            <Center>
                <StyledActivityIndicator />
            </Center>
        )
    }

    if (error) {
        return (
            <Center>
                <StyledText>Could not load post!</StyledText>
            </Center>
        )
    }

    return (
        <StyledScrollView style={styles.main}>
            <Container style={styles.container}>
                <H2 style={styles.postTitle}>{data.post.title}</H2>
                <StyledText size="small" style={styles.postDate}>{format(new Date(data.post.createdAt), 'MMMM d, yyyy')}</StyledText>
                <StyledText style={styles.postContent}>{data.post.content}</StyledText>
            </Container>

            {!!data.post.links.length && (
                <>
                    <Separator style={styles.separator} />

                    <Container style={styles.container}>
                        <Label style={styles.label}>More Info</Label>
                        {data.post.links.map(link => (
                            <StyledLink fontWeight="semibold" style={styles.link} key={link.id} href={link.link}>{link.title}</StyledLink>
                        ))}
                    </Container>
                </>
            )}

            <Separator style={styles.separator} />

            <Container>
                <ShareButton content={createShareableLink(`news/${postId}`)} style={styles.button}>Share Post</ShareButton>
            </Container>
        </StyledScrollView>
    )
}

const styles = StyleSheet.create({
    main: {
        flex: 1,
        marginVertical: 24,
    },
    container: {
        // marginVertical: 24,
    },
    postTitle: {
        marginBottom: 8,
    },
    postDate: {
        color: Theme.colors.textLight,
        marginBottom: 12,
    },
    postContent: {
        marginBottom: 8,
    },
    label: {
        marginBottom: 6,
    },
    link: {
        marginBottom: 2,
    },
    separator: {
        marginTop: 20,
        marginBottom: 16,
    },
    button: {
        marginBottom: 24,
    },
});