import React from 'react';
import { StyleSheet } from 'react-native';

import StyledScrollView from '../components/StyledScrollView';
import StyledText from '../components/StyledText';
import Container from '../components/Container';
import StyledLink from '../components/StyledLink';
import StyledList from '../components/StyledList';
import StyledListItem from '../components/StyledListItem';
import H2 from '../components/H2';

export default function PrivacyPolicyScreen() {
    return (
        <StyledScrollView style={styles.main}>
            <Container>
                <H2 style={styles.title}>Privacy Policy</H2>
                <StyledText style={styles.paragraph}>The UA 179 app was built by Vincent Design Inc. for UA Local 179 as a Free app. This SERVICE is provided by UA Local 179 at no cost and is intended for use as is.</StyledText>
                <StyledText style={styles.paragraph}>To view our privacy policies, see the following links:</StyledText>
                <StyledList style={styles.list}>
                    <StyledListItem style={styles.listItem}><StyledLink href="https://vincentdesign.ca/privacy-policy/">Vincent Design Inc.</StyledLink></StyledListItem>
                    <StyledListItem style={styles.listItem}><StyledLink href="https://ualocal179.ca/privacy">UA Local 179</StyledLink></StyledListItem>
                </StyledList>
                <StyledText style={styles.paragraph}>The app also uses additional third-party services that may collect information used to identify you.</StyledText>
                <StyledText style={styles.paragraph}>Link to the privacy policy of these additional third-party service providers used by the app:</StyledText>
                <StyledList style={styles.list}>
                    <StyledListItem style={styles.listItem}><StyledLink href="https://www.google.com/policies/privacy/">Google Play Services</StyledLink></StyledListItem>
                    <StyledListItem style={styles.listItem}><StyledLink href="https://expo.io/privacy">Expo</StyledLink></StyledListItem>
                </StyledList>
            </Container>
        </StyledScrollView>
    )
}

const styles = StyleSheet.create({
    main: {
        flex: 1,
        marginVertical: 24,
    },
    title: {
        marginBottom: 12,
    },
    heading: {
        fontSize: 16,
        marginBottom: 4,
    },
    paragraph: {
        marginBottom: 12,
    },
    list: {
        marginBottom: 12,
    },
    listItem: {
        marginBottom: 4,
    },
});
