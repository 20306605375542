import React from 'react'
import { ApolloClient, InMemoryCache, ApolloProvider, createHttpLink, fromPromise, from, ApolloLink } from "@apollo/client";
import { setContext } from '@apollo/client/link/context';
import { onError } from "@apollo/client/link/error";

import { refreshTokenAsync, getToken } from './AuthProvider';
import Config from './Config';

/**
 * References
 *  - refresh tokens: https://able.bio/AnasT/apollo-graphql-async-access-token-refresh--470t1c8
 */

const cache = new InMemoryCache();

const httpLink = createHttpLink({
    uri: Config.graphqlUri,
    fetchOptions: {
        // mode: 'no-cors',
    },
});

const authLink = setContext(async (_, { headers }) => {
    const token = await getToken();

    return {
        headers: {
            ...headers,
            Authorization: token ? `JWT ${token}` : "",
        }
    }
});

// only only used here (not in auth) so doesnt log verifyToken and refreshToken requests
const logLink = new ApolloLink((operation, forward) => {
    const filter = (key, value) => (key == 'token' || key == 'headerToken' || key == 'pushToken') && value ? (value.slice(0, 4) + '...' + value.slice(-4)) : value;
    const info = {
        name: operation.operationName,
        headerToken: getToken(),
        variables: operation.variables,
    }
    console.log(`sending request ${JSON.stringify(info, filter, 2)}`)

    return forward(operation).map(data => {
        if (!data.errors)
            console.log('...request successful');
        else
            console.log(`...failed request`)

        return data;
    });

    return forward(operation);
});

const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
    if (graphQLErrors) {
        for (let err of graphQLErrors) {
            if (err.message == "Signature has expired") {
                console.log('Token expired!')
                return fromPromise(
                    refreshTokenAsync()
                ).filter(value => Boolean(value))
                    .flatMap(() => {
                        // retry the request, returning the new observable
                        return forward(operation);
                    });
            } else {
                console.log('[GraphQL Error]:', err)
            }
        }
    }

    if (networkError) {
        console.error(`[Network Error]: ${networkError}`);
    }
});

const client = new ApolloClient({
    link: from([errorLink, authLink, logLink, httpLink]),
    cache
})

export default function ApolloClientProvider({ children }) {
    return (
        <ApolloProvider client={client}>
            {children}
        </ApolloProvider>
    )
}
