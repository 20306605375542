import React from 'react'
import { StyleSheet, View } from 'react-native'
import StyledText from './StyledText'

export default function StyledListItem({ children, style, ...props }) {
    return (
        <StyledText style={[styles.listItem, style]} {...props}>
            -  {children}
        </StyledText>
    )
}

const styles = StyleSheet.create({
    listItem: {
    },
})
