import React from "react";
import { Dimensions } from 'react-native'

const Theme = {
    app: {
        maxWidth: {
            default: Dimensions.get('window').width > 600 ? 420 : 600,
        },
    },
    colors: {
        primary: '#E13838',
        secondary: '#6EA0FF',
        background: '#1C1C1E',
        card: '#181818',
        input: '#000',
        //border: '#484848',
        border: '#434343',
        text: '#fff',
        textLight: '#9E9E9E',
        link: '#0A84FF',
        linkItem: '#6EA0FF',
        largeIcon: '#757575',
    },
    container: {
        padding: 18,
    },
    links: {
        pressOpacity: 0.6, // ios
        pressColor: 'rgba(255, 255, 255, 0.2)', // android
        hoverOpacity: 0.8, // web
        activeOpacity: 0.6, // web
        transitionDuration: '100ms', // web
    },
    buttons: {
        pressOpacity: 0.8, // ios
        pressColor: 'rgba(255, 255, 255, 0.2)', // android
        hoverOpacity: 0.8, // web
        activeOpacity: 0.6, // web
        transitionDuration: '100ms', // web
    },
}

export default Theme;
