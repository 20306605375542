import React from 'react';
import Svg, { Path } from 'react-native-svg';

export default function LeftArrowIcon({ size = 16, color = "currentColor", ...props }) {
    return (
        <Svg width={size} height={size} viewBox="0 0 16 16" fill="none" {...props}>
            <Path fillRule="evenodd" clipRule="evenodd" d="M16 8C16 7.69695 15.8815 7.40631 15.6705 7.19202C15.4596 6.97773 15.1735 6.85735 14.8751 6.85735L3.84233 6.85735L8.67254 1.95309C8.77713 1.84685 8.86009 1.72073 8.91669 1.58192C8.97329 1.44311 9.00243 1.29434 9.00243 1.14409C9.00243 0.993849 8.97329 0.845076 8.91669 0.706267C8.86009 0.567459 8.77713 0.441337 8.67254 0.335097C8.56796 0.228858 8.44379 0.144584 8.30715 0.0870876C8.1705 0.0295916 8.02404 0 7.87613 0C7.72822 0 7.58176 0.0295916 7.44512 0.0870876C7.30847 0.144584 7.18431 0.228858 7.07972 0.335097L0.330474 7.191C0.225718 7.29714 0.142606 7.42324 0.0858978 7.56206C0.0291896 7.70088 0 7.8497 0 8C0 8.1503 0.0291896 8.29912 0.0858978 8.43794C0.142606 8.57676 0.225718 8.70285 0.330474 8.809L7.07972 15.6649C7.29094 15.8795 7.57742 16 7.87613 16C8.17484 16 8.46132 15.8795 8.67254 15.6649C8.88376 15.4503 9.00243 15.1593 9.00243 14.8559C9.00243 14.5525 8.88376 14.2615 8.67254 14.0469L3.84233 9.14265H14.8751C15.1735 9.14265 15.4596 9.02226 15.6705 8.80798C15.8815 8.59369 16 8.30305 16 8Z" fill={color} />
        </Svg>
    );
}
