import React from 'react';
import { StyleSheet, View } from 'react-native';
import { gql, useQuery } from '@apollo/client';

import StyledScrollView from '../components/StyledScrollView';
import Container from '../components/Container';
import LinkListItem from '../components/LinkListItem';
import StyledButton from '../components/StyledButton';
import LogoutIcon from '../icons/LogoutIcon';
import Spacer from '../components/Spacer';
import StyledLink from '../components/StyledLink';

import { useSignOut } from '../App/AuthProvider';
import { isDebug, isDev } from '../util/debug';
import { isDevice } from 'expo-device';

const OUT_OF_WORK_BOARD_QUERY = gql`
  query OutOfWorkBoard
  {
    outOfWorkBoard { 
      date, 
      file 
    }
  }
`;

const LINKS = [
    // [
    // { title: 'My Account', screen: '/my-account' },
    // ],
    [
        { title: 'Courses', screen: '/courses' },
        { title: 'News', screen: '/news' },
        { title: 'Job Postings', screen: '/job-postings' },
        { title: 'Out of Work Board' },
        // { title: 'External Links', screen: '/external-links' },
    ],
    [
        { title: 'Contact UA Local 179', screen: '/more/contact/ua179' },
        { title: 'Contact Sask Piping Ind.', screen: '/more/contact/sask-piping' },
        { title: 'Privacy Policy', screen: '/more/privacy-policy' },
    ],
]

export default function MoreScreen({ navigation }) {
    const signOut = useSignOut();
    const [links, setLinks] = React.useState(LINKS);

    const { loading, error, data } = useQuery(OUT_OF_WORK_BOARD_QUERY);

    React.useEffect(() => {
        if (data) {
            const index = links[0].findIndex(linkItem => linkItem.title == 'Out of Work Board');
            const newOutOfWorkBoardLink = { title: 'Out of Work Board', href: data.outOfWorkBoard.file };
            let newLinks = [...links];
            newLinks[0][index] = newOutOfWorkBoardLink;

            setLinks(newLinks);
        }
    }, [data, setLinks])

    if (error) {
        console.log('Error loading out of work board: ', error.message);
    }

    return (
        <StyledScrollView style={styles.main} contentContainerStyle={styles.scrollViewContentContainer}>
            {links.map((linkGroup, index) => (
                <View key={`link_group_${index}`}>
                    {index !== 0 && (
                        <View key={`separator_${index}`} style={styles.sectionSeparator}></View>
                    )}
                    {linkGroup.map((linkItem) => (
                        <LinkListItem key={linkItem.title} title={linkItem.title} to={linkItem.screen} href={linkItem.href} />
                    ))}
                </View>
            ))}
            <Spacer />
            {(isDebug() || isDev()) && <StyledLink to={{ screen: "Debug" }} style={styles.debugScreenLink}>Debug</StyledLink>}
            <Container style={styles.buttonWrapper}>
                <StyledButton
                    title="Logout"
                    color="primary"
                    icon={LogoutIcon}
                    onPress={signOut}
                />
            </Container>
        </StyledScrollView>
    )
}

const styles = StyleSheet.create({
    main: {
        flex: 1,
    },
    scrollViewContentContainer: {
        minHeight: '100%',
    },
    sectionSeparator: {
        height: 28,
    },
    debugScreenLink: {
        alignSelf: 'center',
    },
    buttonWrapper: {
        marginVertical: 20,
    }
})
