import React from 'react'
import { Dimensions, View, StyleSheet, Image, Platform } from 'react-native'
import { useLinkProps } from '@react-navigation/native';
import { getHeaderTitle, HeaderShownContext } from '@react-navigation/elements';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import Theme from '../App/Theme'
import StyledText from './StyledText';
import { useAuth } from '../App/AuthProvider';
import Pressable from './Pressable';
import { App, isWindowWidthGreaterThanAppWidth } from '../util';

export default function HeaderWithLogo({ navigation, route, options, back }) {
    const insets = useSafeAreaInsets();

    const isParentHeaderShown = React.useContext(HeaderShownContext);

    const statusBarHeight =
        options.headerStatusBarHeight !== undefined
            ? options.headerStatusBarHeight
            : isParentHeaderShown
                ? 0
                : insets.top;

    const headerStyle = Platform.OS === 'web' ?
        styles.header : {
            ...styles.header,
            paddingTop: statusBarHeight,
            height: styles.header.height + statusBarHeight,
        }

    const { token } = useAuth();

    return (
        <View style={headerStyle}>
            <Pressable style={styles.headerLeft} {...useLinkProps({ to: token ? '/my-account' : '/log-in' })}>
                <Image style={styles.logo} resizeMode="contain" source={require('../assets/ua-local-179.png')} fadeDuration={0} />
            </Pressable>
            <View style={styles.title}>
                {options.headerTitleIcon && <options.headerTitleIcon style={styles.titleIcon} />}
                <StyledText style={styles.titleText} fontWeight='semibold'>{getHeaderTitle(options, route.name)}</StyledText>
            </View>
            <View style={styles.headerRight}>
                {options.headerRight && <options.headerRight />}
            </View>
        </View>
    )
}

const styles = StyleSheet.create({
    header: {
        flexDirection: 'row',
        alignItems: 'center',
        backgroundColor: Theme.colors.card,
        borderBottomWidth: StyleSheet.hairlineWidth,
        borderBottomColor: Theme.colors.border,
        height: 82,
        paddingHorizontal: Theme.container.padding,
        paddingBottom: 14,
    },
    headerLeft: {
        flex: 1,
        alignItems: 'flex-start',
        justifyContent: 'center',
    },
    headerRight: {
        flex: 1,
        alignItems: 'flex-end',
        justifyContent: 'center',
        marginTop: 10,
    },
    logo: {
        width: isWindowWidthGreaterThanAppWidth() ? 56 / 0.77 : App.width * 0.18,
        height: isWindowWidthGreaterThanAppWidth() ? 56 : App.width * 0.18 * 0.77,
        resizeMode: 'contain',
        marginLeft: 8,
    },
    title: {
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 10,
    },
    titleText: {
        fontSize: App.width < 400 ? 22 : 24,
        lineHeight: 32,
        color: 'white',
        lineHeight: 32,
        textAlign: 'center',
    },
    titleIcon: {
        color: 'white',
        marginRight: 12,
    }
})
