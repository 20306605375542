import React from 'react';
import Svg, { Path } from 'react-native-svg';

export default function ProfileIcon(props) {
    return (
        <Svg width="27" height="27" viewBox="0 0 27 27" fill="none" {...props}>
            <Path d="M13.5 0C6.18165 0 0 6.18165 0 13.5C0 20.8184 6.18165 27 13.5 27C20.8184 27 27 20.8184 27 13.5C27 6.18165 20.8184 0 13.5 0ZM13.5 6.75C15.8315 6.75 17.55 8.4672 17.55 10.8C17.55 13.1328 15.8315 14.85 13.5 14.85C11.1699 14.85 9.45 13.1328 9.45 10.8C9.45 8.4672 11.1699 6.75 13.5 6.75ZM6.6069 19.9422C7.81785 18.1602 9.83745 16.9722 12.15 16.9722H14.85C17.1639 16.9722 19.1822 18.1602 20.3931 19.9422C18.6678 21.789 16.2203 22.95 13.5 22.95C10.7798 22.95 8.3322 21.789 6.6069 19.9422Z" fill="currentColor" />
        </Svg>
    );
}
