import React from 'react'
import { View, StyleSheet } from 'react-native'

export default function Spacer({ value }) {
    return (
        <View style={styles.spacer(value)} />
    )
}

const styles = StyleSheet.create({
    spacer: flex => ({
        flex,
    }),
})
