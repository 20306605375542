import Constants from 'expo-constants';
import * as Notifications from 'expo-notifications';
import React, { useState, useEffect, useRef } from 'react';
import { Text, View, Button, Platform } from 'react-native';
import { gql, useMutation } from '@apollo/client';
import { useAuth } from './AuthProvider';
import * as Linking from 'expo-linking';
// import { navigate } from './Navigation';

// for development
const USE_EXPO_NOTIFICATIONS = false;

Notifications.setNotificationHandler({
    handleNotification: async () => {
        return {
            shouldShowAlert: true,
            shouldPlaySound: true,
            shouldSetBadge: true,
        }
    },
    handleSuccess: (notificationId) => console.log(`Successfully handled notification: ${notificationId}`),
    handleError: (error) => console.log(`Error handling notification: ${error}`),
});

const ADD_PUSH_TOKEN = gql`
    mutation AddPushToken($deviceType: String, $pushToken: String!) {
        addPushToken(deviceType: $deviceType, pushToken: $pushToken) {
            pushToken
        }
    }
`;

// notification handling

// let pendingNotifications = [];
// let readyToHandleNotifications = false;

// Notifications.addNotificationResponseReceivedListener(response => {
//     console.log('adding notification to pending notifications')
//     pendingNotifications.push(response);
// })

// export const getPendingNotifications = () => {
//     return pendingNotifications;
// }

export const getUrlFromNotificationResponse = (response) => {
    console.log(`Push Notification Response: ${JSON.stringify(response, null, 2)}`);

    const data = USE_EXPO_NOTIFICATIONS ? response.notification.request.content.data :
        Platform.OS == 'ios' ? response.notification.request.trigger.payload :
            Platform.OS == 'android' ? response.notification?.request?.trigger?.remoteMessage?.data :
                null;

    if (data) {
        console.log(`Push Notification Data: ${JSON.stringify(data, null, 2)}`);
        // alert(`Push Notification Data: ${JSON.stringify(data, null, 2)}`);

        switch (data.type) {
            case 'news':
                console.log(`navigating to news post: ${data.postId}`);
                return Linking.createURL(`/news/${data.postId}`);
            default:
                console.log('navigating to my-account')
                return Linking.createURL(`/my-account`);
        }
    } else {
        console.log('Could not get data from notification!');
        // alert('Could not get data from notification!');
    }

    return Linking.createURL('/');
}

// component that handles sending the push token to the server (requires auth)
export default function PushNotifications() {
    const [addPushToken, { loading, error, data }] = useMutation(ADD_PUSH_TOKEN);

    const { token: authToken } = useAuth();

    useEffect(() => {
        if (!authToken)
            return;

        const bootstrapAsync = async () => {
            try {
                const pushToken = await registerForPushNotificationsAsync();

                if (pushToken) {
                    if (USE_EXPO_NOTIFICATIONS) {
                        console.log(pushToken);
                    } else {
                        console.log('sending push token...');

                        try {
                            await addPushToken({
                                variables: {
                                    deviceType: Platform.OS === 'ios' ? 'apple' : 'android',
                                    pushToken,
                                }
                            });
                        } catch (e) {
                            console.log('Error adding push token: ', e.message);
                        }
                    }
                }
                else {
                    console.log("Could not get token for push notifications");
                }
            } catch (e) {
                console.log('Error getting token for push notifications: ', e);
            }
        }

        bootstrapAsync();
    }, [authToken]);

    // useEffect(() => {
    //     readyToHandleNotifications = true;

    //     console.log(`pending notificaitons: ${pendingNotifications.length}`)

    //     while (pendingNotifications.length > 0) {
    //         handleNotificationResponse(pendingNotifications.pop());
    //     }
    // }, [])

    return null;
}

// see https://docs.expo.dev/push-notifications/push-notifications-setup/ 
async function registerForPushNotificationsAsync() {
    let pushToken = null;
    if (Constants.isDevice) {
        const { status: existingStatus } = await Notifications.getPermissionsAsync();
        let finalStatus = existingStatus;
        if (existingStatus !== 'granted') {
            const { status } = await Notifications.requestPermissionsAsync();
            finalStatus = status;
        }
        if (finalStatus !== 'granted') {
            console.log('Failed to get push token for push notification!');
            return;
        }
        if (USE_EXPO_NOTIFICATIONS)
            pushToken = (await Notifications.getExpoPushTokenAsync()).data;
        else
            pushToken = (await Notifications.getDevicePushTokenAsync()).data;
    } else {
        console.log('Must use physical device for Push Notifications');
    }

    if (Platform.OS === 'android') {
        Notifications.setNotificationChannelAsync('default', {
            name: 'default',
            importance: Notifications.AndroidImportance.MAX,
            vibrationPattern: [0, 250, 250, 250],
            lightColor: '#FF231F7C',
        });
    }

    return pushToken;
}