import React from 'react'
import { FlatList } from 'react-native-gesture-handler';

import WithScrollbar, { useScrollbarContext, useScrollbarProps } from './Scrollbar';

export default function StyledFlatList({ children, style, ...props }) {
    // const scrollBarContext = useScrollbarContext();

    return (
        <FlatList style={style} showsVerticalScrollIndicator={false} {...props}>
            {children}
        </FlatList>
        // <WithScrollbar scrollBarContext={scrollBarContext}>
        //     <FlatList style={style} {...useScrollbarProps(scrollBarContext)} {...props}>
        //         {children}
        //     </FlatList>
        // </WithScrollbar>
    )
}
