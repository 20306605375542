import React from 'react';
import Svg, { Path } from 'react-native-svg';

export default function PlusCircledIcon({ size = 16, color = "currentColor", ...props }) {
    return (
        <Svg width={size} height={size} viewBox="0 0 16 16" fill="none" {...props} >
            <Path fillRule="evenodd" clipRule="evenodd" d="M8 0C3.58182 0 0 3.58182 0 8C0 12.4182 3.58182 16 8 16C12.4182 16 16 12.4182 16 8C16 3.58182 12.4182 0 8 0ZM10.696 6.33236C10.8285 6.1952 10.9018 6.01149 10.9001 5.8208C10.8985 5.63011 10.822 5.4477 10.6871 5.31286C10.5523 5.17802 10.3699 5.10153 10.1792 5.09987C9.98851 5.09822 9.8048 5.17152 9.66764 5.304L8 6.97164L6.33236 5.304C6.26528 5.23454 6.18502 5.17913 6.09629 5.14102C6.00756 5.1029 5.91213 5.08284 5.81556 5.082C5.719 5.08116 5.62323 5.09956 5.53385 5.13613C5.44447 5.1727 5.36327 5.2267 5.29499 5.29499C5.2267 5.36327 5.1727 5.44447 5.13613 5.53385C5.09956 5.62323 5.08116 5.719 5.082 5.81556C5.08284 5.91213 5.1029 6.00756 5.14102 6.09629C5.17913 6.18502 5.23454 6.26528 5.304 6.33236L6.97164 8L5.304 9.66764C5.23454 9.73472 5.17913 9.81498 5.14102 9.90371C5.1029 9.99244 5.08284 10.0879 5.082 10.1844C5.08116 10.281 5.09956 10.3768 5.13613 10.4661C5.1727 10.5555 5.2267 10.6367 5.29499 10.705C5.36327 10.7733 5.44447 10.8273 5.53385 10.8639C5.62323 10.9004 5.719 10.9188 5.81556 10.918C5.91213 10.9172 6.00756 10.8971 6.09629 10.859C6.18502 10.8209 6.26528 10.7655 6.33236 10.696L8 9.02836L9.66764 10.696C9.8048 10.8285 9.98851 10.9018 10.1792 10.9001C10.3699 10.8985 10.5523 10.822 10.6871 10.6871C10.822 10.5523 10.8985 10.3699 10.9001 10.1792C10.9018 9.98851 10.8285 9.8048 10.696 9.66764L9.02836 8L10.696 6.33236Z" fill={color} />
        </Svg>
    );
}
