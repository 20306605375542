import React from 'react'
import { View, StyleSheet, Platform, Linking } from 'react-native'
import { Link, useLinkProps } from '@react-navigation/native';
import * as WebBrowser from 'expo-web-browser';

import Pressable from './Pressable';
import StyledText from './StyledText';
import OpenRightIcon from '../icons/OpenRightIcon';
import Theme from '../App/Theme';
import { useLinkStyles } from './StyledLink';

export default function LinkListItem({ title, subtitle, to, action, href, color, disabled, titleStyle, iconStyle, ...props }) {
    let onPress = null, linkProps = null;

    if (href) {
        if (Platform.OS == 'web') {
            linkProps = {
                accessibilityRole: "link",
                href: href,
            };
        } else
            onPress = () => WebBrowser.openBrowserAsync(href);

    } else if (to) {
        ({ onPress, ...linkProps } = useLinkProps({ to, action }));
    }

    const linkStyles = useLinkStyles([
        styles.link,
        disabled && styles.linkDisabled,
    ]);

    const titleStyles = [
        styles.title,
        disabled && styles.titleDisabled,
        color && color === 'primary' && styles.titlePrimary,
        color && color === 'secondary' && styles.titleSecondary,
        titleStyle,
    ];

    const subtitleStyles = [
        styles.subtitle,
        disabled && styles.subtitleDisabled,
    ];

    const iconStyles = [
        styles.icon,
        disabled && styles.iconDisabled,
        color && color === 'primary' && styles.iconPrimary,
        color && color === 'secondary' && styles.iconSecondary,
        iconStyle,
    ];

    // const LinkWrapper = Platform.OS === 'web' ?
    //     ({ children }) => <StyledLink onClick={onPress} to={to} action={action} style={linkStyles}>{children}</StyledLink> :
    //     ({ children }) => <Pressable style={linkStyles} onPress={onPress} unstable_pressDelay={50} android_ripple={true} {...linkProps} {...props}>{children}</Pressable>

    return (
        <Pressable style={linkStyles} onPress={onPress} unstable_pressDelay={50} android_ripple={true} {...linkProps} {...props}>
            <View style={styles.linkInner}>
                <StyledText style={titleStyles} numberOfLines={1}>{title}</StyledText>
                {subtitle && <StyledText size="small" style={subtitleStyles} numberOfLines={1}>{subtitle}</StyledText>}
                <OpenRightIcon style={iconStyles} />
            </View>
        </Pressable>
    )
}



const styles = StyleSheet.create({
    link: {
        display: 'flex',
        alignItems: 'center',
        alignSelf: 'center',
        maxWidth: Theme.app.maxWidth.default,
        width: '100%',
        paddingLeft: Theme.container.padding,
        backgroundColor: Theme.colors.input,
    },
    linkPressed: {
        opacity: Theme.links.pressOpacity,
    },
    linkInner: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        width: '100%',
        paddingVertical: 14,
        paddingLeft: 0,
        paddingRight: Theme.container.padding,
        textAlign: 'left',
        borderBottomWidth: StyleSheet.hairlineWidth,
        borderColor: '#38383A',
    },
    title: {
        flex: 1,
        width: '100%',
        color: Theme.colors.text,
        fontSize: 19,
        paddingRight: 14,
    },
    titlePrimary: {
        color: Theme.colors.primary,
    },
    titleSecondary: {
        color: Theme.colors.secondary,
    },
    titleDisabled: {
        opacity: 0.4,
    },
    subtitle: {
        color: Theme.colors.textLight,
        marginRight: 14,
    },
    subtitleDisabled: {
        opacity: 0.8,
    },
    linkTextSpecial: {
        fontWeight: '600',
        color: Theme.colors.link,
    },
    icon: {
        color: Theme.colors.border,
    },
    iconPrimary: {
        //color: '#BEBEC6',
    },
    iconSecondary: {
        //color: '#BEBEC6',
    },
    iconDisabled: {
        opacity: 0.8,
    },
});
