import { Dimensions, useWindowDimensions } from "react-native";
import Config from "../App/Config";
import Theme from "../App/Theme";



export const App = {
    width: Dimensions.get('window').width > Theme.app.maxWidth.default ? Theme.app.maxWidth.default : Dimensions.get('window').width,
    height: Dimensions.get('window').height,
}

export function useApp() {
    const { width: windowWidth, height: windowHeight } = useWindowDimensions();

    return {
        width: windowWidth > Theme.app.maxWidth.default ? Theme.app.maxWidth.default : windowWidth,
        height: windowHeight,
    }
}

export function isWindowWidthGreaterThanAppWidth() {
    return Dimensions.get('window').width > Theme.app.maxWidth.default;
}

export function createShareableLink(path) {
    return `${Config.webUrl}/${path}`;
}
