import React from 'react';
import Svg, { Path } from 'react-native-svg';

export default function EditIcon(props) {
    return (
        <Svg width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
            <Path d="M13.7283 4.50667L15.4933 6.27084L13.7283 4.50667ZM14.8633 2.95251L10.0908 7.72501C9.84423 7.97126 9.67605 8.28499 9.60749 8.62667L9.16666 10.8333L11.3733 10.3917C11.715 10.3233 12.0283 10.1558 12.275 9.90917L17.0475 5.13667C17.1909 4.99326 17.3047 4.823 17.3823 4.63562C17.4599 4.44824 17.4998 4.24741 17.4998 4.04459C17.4998 3.84177 17.4599 3.64094 17.3823 3.45356C17.3047 3.26618 17.1909 3.09592 17.0475 2.95251C16.9041 2.80909 16.7338 2.69533 16.5464 2.61772C16.3591 2.5401 16.1582 2.50015 15.9554 2.50015C15.7526 2.50015 15.5518 2.5401 15.3644 2.61772C15.177 2.69533 15.0067 2.80909 14.8633 2.95251V2.95251Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <Path d="M15.8333 12.5V15C15.8333 15.442 15.6577 15.866 15.3452 16.1785C15.0326 16.4911 14.6087 16.6667 14.1667 16.6667H4.99999C4.55797 16.6667 4.13404 16.4911 3.82148 16.1785C3.50892 15.866 3.33333 15.442 3.33333 15V5.83335C3.33333 5.39133 3.50892 4.9674 3.82148 4.65484C4.13404 4.34228 4.55797 4.16669 4.99999 4.16669H7.49999" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </Svg>

    );
}
