import React from 'react';
import 'react-native-gesture-handler';
import PushNotifications from './App/PushNotifications';

import { StatusBar } from 'expo-status-bar';
import { Dimensions, Platform, StyleSheet, View } from 'react-native';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import { GestureHandlerRootView } from 'react-native-gesture-handler';

import ApolloClientProvider from './App/ApolloClientProvider';
import WaitForAppToLoad from './App/WaitForAppToLoad';
import AuthProvider from './App/AuthProvider';
import Navigation from './App/Navigation';
import Theme from './App/Theme';
import { isDebug } from './util/debug';

import { isWindowWidthGreaterThanAppWidth } from './util';

// throw new Error("Test Sentry error!");

export default function App() {
    return (
        <GestureHandlerRootView style={styles.root}>
            <ApolloClientProvider>
                <AuthProvider>
                    <PushNotifications />
                    <WaitForAppToLoad>
                        <SafeAreaProvider style={styles.container}>
                            <View style={styles.main}>
                                <Navigation />
                                <StatusBar style="light" backgroundColor={Theme.colors.card} />
                            </View>
                        </SafeAreaProvider>
                    </WaitForAppToLoad>
                </AuthProvider>
            </ApolloClientProvider>
        </GestureHandlerRootView>
    );
}

const styles = StyleSheet.create({
    root: {
        flex: 1,
    },
    container: {
        flex: 1,
        backgroundColor: Theme.colors.background,
        overflow: 'hidden',
    },
    main: {
        flex: 1,
        width: '100%',
        alignSelf: 'center',
        maxWidth: Theme.app.maxWidth.default,
        borderLeftWidth: isWindowWidthGreaterThanAppWidth() ? StyleSheet.hairlineWidth : 0,
        borderRightWidth: isWindowWidthGreaterThanAppWidth() ? StyleSheet.hairlineWidth : 0,
        borderColor: Theme.colors.border
    },
});
