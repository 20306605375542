import React from 'react';
import Svg, { Path } from 'react-native-svg';

export default function SearchIcon({ size = 26, color = "currentColor", ...props }) {
    return (
        <Svg width={size} height={size} viewBox="0 0 26 26" fill="none" {...props}>
            <Path fillRule="evenodd" clipRule="evenodd" d="M18.6226 16.768L25.6163 23.7619C25.8621 24.0078 26.0001 24.3414 26 24.6892C25.9999 25.037 25.8616 25.3704 25.6156 25.6163C25.3696 25.8621 25.0361 26.0001 24.6883 26C24.3405 25.9999 24.0071 25.8616 23.7613 25.6156L16.7676 18.6218C14.6769 20.2411 12.0479 21.0032 9.41529 20.7529C6.78272 20.5026 4.34437 19.2588 2.59627 17.2745C0.84818 15.2902 -0.0783409 12.7144 0.00519498 10.0713C0.0887309 7.42807 1.17605 4.91598 3.04595 3.04603C4.91586 1.17608 7.42788 0.0887331 10.071 0.00519511C12.7141 -0.0783429 15.2898 0.848201 17.2741 2.59634C19.2583 4.34447 20.5021 6.78289 20.7524 9.41553C21.0026 12.0482 20.2406 14.6773 18.6213 16.768H18.6226ZM10.4005 18.1993C12.4691 18.1993 14.4529 17.3775 15.9157 15.9148C17.3784 14.452 18.2001 12.4681 18.2001 10.3994C18.2001 8.3308 17.3784 6.34688 15.9157 4.88413C14.4529 3.42138 12.4691 2.59961 10.4005 2.59961C8.33189 2.59961 6.34802 3.42138 4.88531 4.88413C3.42259 6.34688 2.60085 8.3308 2.60085 10.3994C2.60085 12.4681 3.42259 14.452 4.88531 15.9148C6.34802 17.3775 8.33189 18.1993 10.4005 18.1993Z" fill={color} />
        </Svg>
    );
}
