import React from 'react';
import Svg, { Path } from 'react-native-svg';

export default function ShareIcon({ size = 17, color = 'currentColor', ...props }) {
    return (
        <Svg width={size} height={size} viewBox="0 0 17 17" fill="none" {...props}>
            <Path d="M1 8.5V14.5C1 14.8978 1.19754 15.2794 1.54917 15.5607C1.90081 15.842 2.37772 16 2.875 16H14.125C14.6223 16 15.0992 15.842 15.4508 15.5607C15.8025 15.2794 16 14.8978 16 14.5V8.5" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <Path d="M12.5 4.21429L8.5 1L4.5 4.21429" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <Path d="M8.5 1V11.4464" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </Svg>
    );
}
