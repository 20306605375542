import { Linking, Platform } from 'react-native';

export const openMap = async (address) => {
    const url = Platform.select({
        'android': `geo:0,0?q=${address}`,
        'ios': `http://maps.apple.com/?q=${address}`,
        'web': `https://maps.google.com/?q=${address}`,
    })

    try {
        const supported = await Linking.canOpenURL(url);
        if (supported)
            await Linking.openURL(url);
    } catch (error) {
        console.error(error.message);
    }
}
