import React from 'react'
import { View, Text, StyleSheet, Platform } from 'react-native'
import { useLinkProps } from '@react-navigation/native';
import Theme from '../App/Theme';

import Container from './Container';
import H2 from './H2';
import StyledLink, { useLinkStyles } from './StyledLink';
import StyledText from './StyledText';
import Pressable from './Pressable';

const trimChars = (excerpt, maxLength) => {
    if (excerpt.length <= maxLength)
        return excerpt;

    return excerpt.slice(0, maxLength) + '...';
}

/**
 * List item used for Job and News Posts.
 */

export default function PostItem({ title, dateText, excerpt, linkTo, readMoreText = "Read More" }) {
    let Wrapper = ({ children }) => children;

    if (Platform.OS !== 'web') {
        const { onPress, ...linkProps } = useLinkProps({ to: linkTo });

        Wrapper = ({ children }) => <Pressable onPress={onPress} style={useLinkStyles()} android_ripple={true} {...linkProps}>{children}</Pressable>;
    }

    return (
        <Wrapper>
            <Container style={styles.container}>
                <H2 style={styles.title}>{title}</H2>
                <StyledText size="small" style={styles.datePosted}>{dateText}</StyledText>
                <StyledText fontWeight="semibold" style={styles.excerpt}>{trimChars(excerpt, 320)}</StyledText>
                <StyledLink to={Platform.OS === 'web' && linkTo} style={styles.readMore}>{readMoreText}</StyledLink>
            </Container>
        </Wrapper>
    )
}


const styles = StyleSheet.create({
    container: {
        paddingVertical: 8,
    },
    title: {
        marginTop: 16,
        marginBottom: 8,
    },
    datePosted: {
        color: Theme.colors.textLight,
        marginBottom: 12,
    },
    excerpt: {
        marginBottom: 16,
    },
    readMore: {
        alignSelf: 'flex-start',
        marginBottom: 12,
    },
    separator: {
        marginVertical: 8,
    },
});
