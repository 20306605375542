import React from 'react'
import { StyleSheet, Linking, Platform, View } from 'react-native'

import Pressable from './Pressable';
import StyledText from './StyledText';
import Theme from '../App/Theme';

/**
 * Styled button component.
 * 
 * Supports color, href, and fontWeight.
 */

export default function StyledButton({ children, title, href, onPress, style, buttonStyle, textStyle, fontWeight, color, disabled, icon: Icon, ...props }) {
    const wrapperStyles = [
        styles.wrapper,
        style,
    ]

    const buttonStyles = [
        color == 'primary' && styles.buttonPrimary,
        color == 'secondary' && styles.buttonSecondary,
        Icon && styles.buttonWithIcon,
        disabled && styles.buttonDisabled,
        buttonStyle,
    ];

    const textStyles = [
        styles.text,
        Icon && styles.textWithIcon,
        disabled && styles.textDisabled,
        textStyle,
    ]

    fontWeight = fontWeight !== undefined ? fontWeight :
        color === 'primary' || color == 'secondary' ? 'semibold' : null;

    function handlePress() {
        href && Linking.openURL(href);
        onPress && onPress();
    }

    return (
        <View style={wrapperStyles}>
            <Pressable disabled={disabled} onPress={handlePress} {...useButtonProps({ style: buttonStyles })} {...props}>
                <StyledText
                    style={textStyles}
                    fontWeight={fontWeight}
                    {...props}
                >
                    {title || children}
                </StyledText>
                {!!Icon && (
                    <Icon style={styles.icon} />
                )}
            </Pressable>
        </View>
    )
}

const styles = StyleSheet.create({
    wrapper: {
        borderRadius: 13,
        overflow: 'hidden',
    },
    button: {
        textAlign: 'center',
        alignItems: 'center',
        width: '100%',
        paddingVertical: 12,
        paddingHorizontal: 16,
        backgroundColor: '#353535',
        color: '#fff',
        ...Platform.select({
            web: {
                cursor: 'pointer',
                outlineStyle: 'none',
                transitionDuration: Theme.buttons.transitionDuration,
            }
        })
    },
    buttonPressed: {
        opacity: Platform.OS == 'android' ? 1.0 : Theme.buttons.pressOpacity,
    },
    buttonHovered: {
        opacity: Theme.buttons.hoverOpacity,
    },
    buttonFocused: {
        opacity: Theme.buttons.hoverOpacity,
    },
    buttonActive: {
        opacity: Theme.buttons.activeOpacity,
    },
    buttonDisabled: {
        opacity: 1.0,
    },
    buttonPrimary: {
        backgroundColor: Theme.colors.primary,
    },
    buttonSecondary: {
        backgroundColor: Theme.colors.secondary,
    },
    buttonWithIcon: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        textAlign: 'left',
    },
    text: {
        fontSize: 17,
        lineHeight: 24,
        flex: 1,
    },
    textDisabled: {
        opacity: 0.5,
    },
    icon: {
        display: 'flex',
        color: 'white',
        marginLeft: 8,
    }
});

export const useButtonProps = (props) => ({
    style: ({ pressed, hovered, active, focused }) => [
        styles.button,
        pressed && styles.buttonPressed,
        hovered && styles.buttonHovered,
        focused && styles.buttonFocused,
        active && styles.buttonActive,
        typeof props?.style === 'function' ? props?.style({ pressed, hovered, active, focused }) : props?.style,
    ],
    android_ripple: { color: Theme.buttons.pressColor },
})
