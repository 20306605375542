import React from 'react'
import { View, StyleSheet } from 'react-native'
import { gql, useMutation } from '@apollo/client';

import StyledScrollView from '../components/StyledScrollView';
import StyledActivityIndicator from '../components/StyledActivityIndicator';
import StyledTextInput from '../components/StyledTextInput';
import StyledText from '../components/StyledText';
import Pressable from '../components/Pressable';
import Container from '../components/Container';
import Label from '../components/Label';
import XIcon from '../icons/XIcon';

import Theme from '../App/Theme';

// mutation
const UPDATE_MEMBER = gql`
    mutation UpdateUser($email: String, $firstName: String, $lastName: String) {
        updateUser(email: $email, firstName: $firstName, lastName:$lastName) {
            member {
                email
                firstName
                lastName
            }
        }
    }
`;

export default function UserProfileEditScreen({ route, navigation, profileScreen = 'UserProfile' }) {
    const { memberData } = route.params;

    const [email, setEmail] = React.useState(memberData.email);
    const [firstName, setFirstName] = React.useState(memberData.firstName);
    const [lastName, setLastName] = React.useState(memberData.lastName);

    const [save, { loading: saving, error }] = useMutation(UPDATE_MEMBER, {
        variables: {
            email,
            firstName,
            lastName
        }
    })

    const onSave = React.useCallback(async () => {
        console.log('saving profile');

        try {
            const response = await save();
            setEmail(response.data.updateUser.member.email);
            setFirstName(response.data.updateUser.member.firstName);
            setLastName(response.data.updateUser.member.lastName);
            navigation.navigate(profileScreen, { editedData: response.data.updateUser.member });
        } catch (e) {
            console.log('Error saving profile: ', e.message);
        }
    }, [navigation, profileScreen, save])

    const onExit = React.useCallback(() => {
        navigation.goBack();
    }, [navigation])

    React.useLayoutEffect(() => {
        navigation.setOptions({
            headerRight: () => (
                <Pressable onPress={onSave} style={styles.saveButton}>
                    <StyledText fontWeight="semibold" style={styles.saveButtonText}>Save</StyledText>
                </Pressable>
            )
        });
    }, [navigation, onSave])

    React.useLayoutEffect(() => {
        navigation.setOptions({
            headerLeft: () => (
                <Pressable onPress={onExit} style={styles.exitButton}>
                    <XIcon size={28} color={Theme.colors.primary} />
                </Pressable>
            )
        });
    }, [navigation, onSave])

    return (
        <StyledScrollView style={styles.main}>
            <Container>
                {/* <Label style={styles.label}>Member Image</Label>
                <Image source={{ uri: data.member.image }} resizeMode="cover" style={styles.profileImage} /> */}

                <Label style={styles.label}>Email</Label>
                <StyledTextInput value={email} onChangeText={setEmail} style={styles.textInput} />

                <View style={styles.nameEditWrapper}>
                    <View style={styles.firstNameWrapper}>
                        <Label style={styles.label}>First Name</Label>
                        <StyledTextInput value={firstName} onChangeText={setFirstName} style={styles.textInput} />
                    </View>
                    <View style={styles.lastNameWrapper}>
                        <Label style={styles.label}>Last Name</Label>
                        <StyledTextInput value={lastName} onChangeText={setLastName} style={styles.textInput} />
                    </View>
                </View>

                {/* <Label style={styles.label}>Member Number</Label>
                <StyledTextInput style={styles.textInput}>{data.member.id}</StyledTextInput> */}

                {/* <Label style={styles.label}>Date of Birth</Label>
                <StyledTextInput value={dob} onChangeText={setDob} style={styles.textInput} /> */}

                <StyledActivityIndicator size="small" animating={saving} style={styles.savingIndicator} />
            </Container>
        </StyledScrollView>
    )
}

const styles = StyleSheet.create({
    main: {
        flex: 1,
        paddingTop: 48,
    },
    profileImage: {
        width: 100,
        height: 100,
        borderRadius: 50,
        marginBottom: 20,
        backgroundColor: '#7e7e7e'
    },
    label: {
        marginBottom: 4,
    },
    textInput: {
        marginBottom: 20,
    },
    nameEditWrapper: {
        flexDirection: 'row',
    },
    firstNameWrapper: {
        flex: 1,
        marginRight: 4,
    },
    lastNameWrapper: {
        flex: 1,
        marginLeft: 4,
    },
    savingIndicator: {
        alignSelf: 'center',
        marginBottom: 20,
    },
    saveButton: {
        marginRight: Theme.container.padding,
    },
    saveButtonText: {
        color: Theme.colors.secondary,
        fontSize: 18,
    },
    exitButton: {
        marginLeft: Theme.container.padding,
    },
})
