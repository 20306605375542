import React from 'react';
import Svg, { Circle } from 'react-native-svg';

export default function EllipsesIcon({ size = 25, color = "currentColor", ...props }) {
    return (
        <Svg width={size} height={size} viewBox="0 0 25 25" fill="none" {...props}>
            <Circle cx="12.5" cy="12.5" r="2.5" fill={color} />
            <Circle cx="22.5" cy="12.5" r="2.5" fill={color} />
            <Circle cx="2.5" cy="12.5" r="2.5" fill={color} />
        </Svg>
    );
}
