import React from 'react'
import { View, StyleSheet, Platform } from 'react-native'
import { getHeaderTitle, HeaderShownContext } from '@react-navigation/elements';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import HeaderBackButton from './HeaderBackButton';
import StyledText from './StyledText';
import Theme from '../App/Theme'

export default function HeaderWithLargeTitle({ navigation, route, options, back }) {
    const insets = useSafeAreaInsets();

    const isParentHeaderShown = React.useContext(HeaderShownContext);

    const statusBarHeight =
        options.headerStatusBarHeight !== undefined
            ? options.headerStatusBarHeight
            : isParentHeaderShown
                ? 0
                : insets.top;

    const headerStyle = [
        styles.header,
        {
            paddingTop: statusBarHeight,
            height: (Platform.OS == 'ios' ? 96 : 86) + statusBarHeight,
        }
    ];

    return (
        <View style={headerStyle}>
            <HeaderBackButton navigation={navigation} options={options} back={back} />
            <StyledText style={styles.title} fontWeight='bold'>{getHeaderTitle(options, route.name)}</StyledText>
        </View>
    )
}

const styles = StyleSheet.create({
    header: {
        backgroundColor: Theme.colors.card,
        borderBottomWidth: StyleSheet.hairlineWidth,
        borderBottomColor: Theme.colors.border,
    },
    title: {
        fontSize: 34,
        color: 'white',
        lineHeight: 42,
        paddingLeft: Theme.container.padding,
    },
})
